import React from "react";
import PropTypes from "prop-types";

const MapSelectedStation = ({ stations, selectedStationId }) => {
  if (!selectedStationId || !stations) return "";
  let selectedStation = stations.find(s => s.id === selectedStationId);
  if (!selectedStation) return "";

  return (
    <div className="legend">
      <b>{selectedStation.station_name}</b>
      <br />
      {selectedStation.street_address}
      <br />
      {`${selectedStation.city}, ${selectedStation.state} ${
        selectedStation.zip
      }`}
      <br />
      {selectedStation.station_phone}
    </div>
  );
};

export default MapSelectedStation;

MapSelectedStation.propTypes = {
  stations: PropTypes.array,
  selectedStationId: PropTypes.number
};
