import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "./../../components/IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "./../../components/IncentivesPreferences/IncentivePreferences";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage, useIntl } from 'react-intl';

const Incentives = ({ electricVehicles, incentives, ip, uuid }) => {

  useEffect(() => {
    document.title = `${process.env.REACT_APP_PAGES_INCENTIVES_TITLE} - Austin Energy EV Buyers Guide`;
  });

  const intl = useIntl()

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-sm-12 banner-center">
            <h1>
              <FormattedMessage 
                id="incentives.title"
                defaultMessage="Electric Vehicle Incentives"
                description="Electric Vehicle Incentives"
              />
            </h1>
            <div className="wrapper">
              <p className="lead">
                <FormattedMessage 
                  id="incentives.subTitle"
                  defaultMessage="You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Incentives are personalized for where you live."
                  description="Incentives Subtitle"
                />
              </p>
              <IncentivePreferences
                incentives={incentives}
                electricVehicles={electricVehicles}
                titleText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
                btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
              />
            </div>
          </div>
        </div>
        <br />
        {renderIncentives}
        <br />
        <p className="incentives-disclaimer">Live in an apartment building? Landlords and tenants, <a href="https://austinenergy.com/green-power/plug-in-austin/workplace-charging" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>click here</a> to learn about our Multi-Family Charging programs.</p>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
