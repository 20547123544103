import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import "./Inventory.scss";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"
import fetchInventory from "../../services/fetchInventory"
import InventoryFilterControls from "../../components/InventoryFilterControls/InventoryFilterControls"

import InventoryDealersFilter from "../../components/InventoryDealersFilter/InventoryDealersFilter"
import InventoryCatalog from "../../components/InventoryCatalog/InventoryCatalog"
import EvSortControls from "../../components/EvSortControls/EvSortControls"
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent"
import isIE from "../../utils/isIE";

const Inventory = ({ electricVehicles, dealerLocations, ip, uuid }) => {
  const userPrefs = useContext(UserPrefsContext);
  const [inventory, setInventory] = useState([])

  const zipcode = userPrefs.get('zipcode')

  useEffect ( () => {
    let ignore = false;
    async function fetchData() {
      const params = {
        postcode: zipcode,
        distance: isIE() ? 25 : 100
      }
      try {
        const inventory = await fetchInventory(params)
        if (!ignore) setInventory(inventory)
      } catch (e) {
        console.log("Failed to load inventory")
      }
    }

    fetchData()
    document.title = process.env.REACT_APP_PAGES_INVENTORY_TITLE;

    return () => { ignore = true; }

  }, [zipcode])

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <InventoryFilterControls vehicles={inventory}/>
      <br />
      <InventoryDealersFilter inventory={inventory}/>
    </>
  );

  return (
    <section className="container inventory">
      <div className="row mb-3">
        <div className="col-sm-12">
          <div className="banner-center">
          <h1><FormattedMessage 
              id="browseInventory"
              defaultMessage="Browse Nearby Inventory"
              description="Inventory Title"
            /></h1>
            </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="pull-right">
            <EvSortControls />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
        <ModalComponent
            buttonText= "Inventory Filters"
            titleText="Inventory Filters"
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>Results have been updated as of {time}.</span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <InventoryCatalog vehicles={inventory}/>
        </div>
      </div>
    </section>
  );
};

export default Inventory;

Inventory.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
