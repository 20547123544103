import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import logo from "../../../assets/images/logo.jpg";
import "./Header.scss";
import { FormattedMessage } from "react-intl";

const Header = ({ page, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="https://austinenergy.com"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                className="img-fluid"
                style={{ maxWidth: "116px" }}
                alt="Austin Energy Homepage"
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'}>
            <span style={{ display: "none" }}>Mobile Toggle Menu</span>
            <span className="navbar-toggler-icon"></span>
          </NavbarToggler>
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED ? (
                <NavItem>
                  <Link
                    aria-label="Link to Homepage"
                    to="/"
                    className={isHomepage ? "active" : ""}
                  >
                    <span>
                      <FormattedMessage
                        id="home"
                        defaultMessage="Home"
                        description="Home Header"
                      />
                    </span>
                  </Link>
                </NavItem>
              ) : null}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={page === "dealers" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="shopLocal"
                      defaultMessage="Shop Local EVs"
                      description="Shop Local EVs Header"
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu className="text-center">
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-browse-nearby-inventory`}
                    title={page === "dealer" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/inventory"}
                    aria-label="Link to Inventory"
                  >
                    <FormattedMessage
                      id="browseInventory"
                      defaultMessage="Browse Nearby Inventory"
                      description="Browse Nearby Inventory Header"
                    />
                  </DropdownItem>
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-dealers`}
                    title={page === "dealer" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/dealers"}
                    aria-label="Link to Dealers"
                  >
                    <FormattedMessage
                      id="dealerDirectory"
                      defaultMessage="Dealer Directory"
                      description="Dealer Directory Header"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  title={page === "vehicles" ? "Active Page" : null}
                  to="/vehicles"
                  aria-label="Link to Vehicles"
                >
                  <span>
                    <FormattedMessage
                      id="electricVehicles"
                      defaultMessage="Electric Vehicles"
                      description="Electric Vehicles Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/used-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="usedElectricVehicles"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                    aria-label="Link to Incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "map" ? "Active Page" : null}
                    to="/charging-stations"
                    aria-label="Link to Charging Stations"
                  >
                    <span>
                      <FormattedMessage
                        id="chargingStations"
                        defaultMessage="Charging Stations"
                        description="Charging Stations Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={
                      page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK
                        ? "Active Page"
                        : null
                    }
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                    aria-label="Link to EV 101"
                  >
                    <span>
                      <FormattedMessage
                        id="evFacts"
                        defaultMessage="EV Facts"
                        description="EV Facts Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "electricians" ? "Active Page" : null}
                    to="/electricians"
                  >
                    <span>
                      <FormattedMessage
                        id="electricians"
                        defaultMessage="Electricians"
                        description="Electricians Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                    aria-label="Link to FAQ"
                  >
                    <span>
                      <FormattedMessage
                        id="faq"
                        defaultMessage="FAQ"
                        description="FAQ Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_ERIDES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "e-ride" ? "Active Page" : null}
                    to="/e-ride"
                    aria-label="Link to E-Ride"
                  >
                    <span>
                      <FormattedMessage
                        id="e-ride"
                        defaultMessage="E-Ride"
                        description="E-Ride Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="active">
                  <span>{language === "EN" ? "English" : "Spanish"}</span>
                </DropdownToggle>
                <DropdownMenu className="text-center">
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-language-english`}
                    title={language === "EN" ? "Active Language" : null}
                    tag={NavLink}
                    to={"#"}
                    aria-label="Change to English Language"
                    onClick={() => changeLanguage("EN")}
                  >
                    English
                  </DropdownItem>
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-language-spanish`}
                    title={language === "ES" ? "Active Language" : null}
                    tag={NavLink}
                    to={"#"}
                    aria-label="Change to Spanish Language"
                    onClick={() => changeLanguage("ES")}
                  >
                    Spanish
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
