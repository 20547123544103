import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "./Footer.scss";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="container pt-5 pb-4">
        <div className="row">
          <div className="col-md-4 mb-3">
            <p className="h3"><FormattedMessage
              id="pages"
              defaultMessage="Pages"
              description="Pages"
            /></p>
            <Link aria-label="Link to Homepage" to="/"><FormattedMessage
              id="home"
              defaultMessage="Home"
              description="Home Header"
            /></Link>
            <br />
            {process.env.REACT_APP_PAGES_INVENTORY_ENABLED ? (<>
              <Link aria-label="Link to Inventory" to="/inventory"><FormattedMessage
                id="footerInventory"
                defaultMessage="Inventory - Austin Energy EV Buyer's Guide"
                description="Inventory - Austin Energy EV Buyer's Guide Footer"
              /></Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link aria-label="Link to Vehicles" to="/vehicles">
                <FormattedMessage
                  id="electricVehicles"
                  defaultMessage="Electric Vehicles"
                  description="Electric Vehicles Header"
                /></Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
              <Link aria-label="Link to Incentives" to="/incentives">
                <FormattedMessage
                  id="incentives"
                  defaultMessage="Incentives"
                  description="Incentives Header"
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
              <Link aria-label="Link to Charging Stations" to="/charging-stations">
                <FormattedMessage
                  id="chargingStations"
                  defaultMessage="Charging Stations"
                  description="Charging Stations Header"
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (<>
              <Link aria-label="Link to EV 101" to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}>
                <FormattedMessage
                  id="evFacts"
                  defaultMessage="EV Facts"
                  description="EV Facts Footer"
                /></Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (<>
              <Link aria-label="Link to Dealers" to="/dealers"><FormattedMessage
                id="findDealers"
                defaultMessage="Find Dealers"
                description="Find Dealers Footer"
              /></Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (<>
              <Link aria-label="Link to FAQ" to="/faq"><FormattedMessage
                id="evFAQ"
                defaultMessage="EV FAQ"
                description="EV Faq Footer"
              /></Link>
              <br />
            </>) : (null)}
          </div>
          <div className="col-md-4 mb-3">
            <p className="h3"><FormattedMessage
              id="footerNavigationOtherResources"
              defaultMessage="Other Austin Energy Resources"
              description="Other Austin Energy Resources Footer"
            /></p>
            <a
              href="https://austinenergy.com/ae/green-power/plug-in-austin/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Read more about Other Austin Energy Resources"
            >
              <FormattedMessage
                id="footerNavigationEVPrograms"
                defaultMessage="Austin Energy EV Programs"
                description="Austin Energy EV Programs Footer"
              />
            </a>
            <br />
            <a
              href="https://austinenergy.com/ae/green-power/solar-solutions"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Read more about Solar Solutions"
            >
              <FormattedMessage
                id="footerNavigationSolar"
                defaultMessage="Solar"
                description="Solar Footer"
              />
            </a>
            <br />
            <a
              href="https://austinenergy.com/ae/green-power/greenchoice/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Read more about Green Choice"
            >
              <FormattedMessage
                id="footerNavigationGreenChoice"
                defaultMessage="GreenChoice®"
                description="GreenChoice® Footer"
              />
            </a>
            <br />
            <a
              href="https://austinenergy.com/ae/energy-efficiency/green-building"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Read more about Green Building"
            >
              <FormattedMessage
                id="footerNavigationGreenBuilding"
                defaultMessage="Austin Energy Green Building"
                description="Austin Energy Green Building Footer"
              />
            </a>
            <br />
            <a
              href="https://savings.austinenergy.com/rebates/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Read more about Austin Energy Rebates"
            >
              <FormattedMessage
                id="footerNavigationRebatesIncentives"
                defaultMessage="Rebates & Incentives"
                description="Rebates & Incentives Footer"
              />
            </a>
            <br />
          </div>
          <div className="col-md-4 mb-3">
            <p className="h3"><FormattedMessage
              id="footerNavigationContact"
              defaultMessage="Contact"
              description="Contact Footer"
            /></p>
            <p className="small">
              <a
                style={{ color: "white", textTransform: "lowercase" }}
                href={process.env.REACT_APP_COMPANY_CONTACT}
                aria-label="Learn more about Austin Energy"
              >
                {process.env.REACT_APP_COMPANY_CONTACT_DISPLAY}
              </a>
              <br />
              <a
                href="https://austinenergy.com/ae/green-power/plug-in-austin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                pluginaustin.com
              </a>
            </p>
              <div className="disclaimer-container">
                     <DisclaimerComponent clientName="zappynobackground" />
              </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundColor: "black" }}>
        <div className="container" style={{ padding: "10px" }}>
          <p style={{ marginBottom: 0, color: "#fff", fontSize: "11px" }}>
            <span style={{ fontFamily: "Arial, Helvetica" }}>&copy;</span>
            {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
