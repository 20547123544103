import React from "react";
import "./AustinBikeMap.scss"
import { FormattedMessage } from "react-intl";

const AustinMap = () => {

  return (
    <section className="container pb-0" id="AustinBikeMap">
      <div className="header">
        <FormattedMessage
          id="eRides.bikeMap.title"
          defaultMessage="Austin Bike Map"
          description="MetroBike Title"
        />
      </div>
      <div className="subtitle">
        <FormattedMessage
          id="eRides.bikeMap.subtitle"
          defaultMessage="Austin’s biking network is growing every day."
          description="MetroBike subTitle"
        />
      </div>
      <div className="austinMap">
        <iframe
          width="968"
          height="497"
          title="ERides Bike Map"
          src="https://austin.maps.arcgis.com/apps/webappviewer/index.html?id=c7fecf32a2d946fabdf062285d58d40c&extent=3052120.7123%2C10036958.1486%2C3179054.0456%2C10097891.4819%2C102739"
        />
      </div>
    </section>
  );
};


export default AustinMap;
