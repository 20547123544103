import React from "react";
import "./MetroBikeAccess.scss";
import { FormattedMessage } from "react-intl";

const MetroBikeAccess = () => {
  return (
    <section className="pb-0" id="MetroBikeAccess">
      <div className="left-box"></div>
      <div className="right-box">
        <div className="title">
          <FormattedMessage
            id="eRides.metroBike.title"
            defaultMessage="MetroBike Access"
            description="MetroBike Title"
          />
        </div>
        <div className="subtitle">
          <FormattedMessage
            id="eRides.metroBike.subtitle"
            defaultMessage="No bike? No problem. Try a &nbsp; {metroBike} &nbsp; and enjoy a smooth ride around town
        for short trips without buying a bike."
            description="MetroBike subTitle"
            values={{
              metroBike: (
                <>
                  &nbsp;
                  <a
                    href={`https://www.capmetro.org/ourservices/metrobike`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="metroBike-link"
                  >
                    <FormattedMessage
                      id="eRides.metroBike"
                      defaultMessage="Metrobike"
                      description="MetroBike Link"
                    />
                  </a>
                  &nbsp;
                </>
              ),
            }}
          />
        </div>
        <div className="access-button">
          <a
            href={`https://rebates.austinenergy.com/OnlineApp/#enrollment/?programId=1064`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-orange"
          >
            <FormattedMessage
              id="eRides.metroBike.try"
              defaultMessage="Try a Metrobike"
              description="ERides Cards button"
            />
          </a>
        </div>
        <div className="help-buttons">
          <a
            href={`https://austin.bcycle.com/stations`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-white"
          >
            <FormattedMessage
              id="eRides.metroBike.find"
              defaultMessage="Find Bikes"
              description="ERides Cards button"
            />
          </a>
          <a
            href={`https://www.capmetro.org/ourservices/metrobike`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-white access-help"
          >
            <FormattedMessage
              id="eRides.metroBike.how"
              defaultMessage="How to Use"
              description="ERides Cards button"
            />
          </a>
          <a
            href={`https://www.austintexas.gov/page/bicycle-and-micromobility-laws-and-safety#:~:text=Yield%20to%20pedestrians.,side%2Dby%2Dside).`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-white access-help"
          >
            <FormattedMessage
              id="eRides.metroBike.saftey"
              defaultMessage="Safety Tips"
              description="ERides Cards button"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default MetroBikeAccess;
