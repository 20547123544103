import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const SlideMilesDrivenAnnually = ({
  id = "miles-driven-annually-range",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "ev.milesDrivenAnnually", defaultMessage: "Miles Driven Per Year"})
  const description = val => {
    return `${FormatAsThousands(val)} ${intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}`;
  };

  return (
    <Range
      id={id}
      value={userPrefs.get("milesDrivenAnnually")}
      label={label}
      rangeMin={1000}
      rangeMax={100000}
      rangeStep={1000}
      description={description}
      handler={e => userPrefs.set({ milesDrivenAnnually: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenAnnually;

SlideMilesDrivenAnnually.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
