import React, { useContext } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

// import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";

const SlideMilesDrivenDaily = ({
  id = "miles-driven-daily-range",
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();
  const label = intl.formatMessage ? intl.formatMessage({ id: "evs.roundTripCommute", defaultMessage: "Roundtrip Commute"}) : "Roundtrip Commute";
  const description = val => {
    const unit = intl.formatMessage ? intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"}) : "miles";
    return val + " " + unit;
  };
  
  return (
    <Range
      id={id}
      value={userPrefs.get("milesDrivenDaily")}
      label={label}
      rangeMin={1}
      rangeMax={150}
      rangeStep={1}
      description={description}
      ariaControls="electric-vehicles-catalog"
      handler={e => userPrefs.set({ milesDrivenDaily: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenDaily;

SlideMilesDrivenDaily.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
