import {
  ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR,
  MAINTENANCE_COST_SCALING_FACTOR,
  AVERAGE_MAINTENANCE_COST_PER_MILE,
  AVERAGE_MSRP
} from "../../../../client_customizations/data/assumptions/ASSUMPTIONS"

const MaintenanceCost = {
  maintenanceCostPerGasMile: function(vehicle) {
    return (
      AVERAGE_MAINTENANCE_COST_PER_MILE.value *
      (1 +
        MAINTENANCE_COST_SCALING_FACTOR.value *
          (vehicle.msrp / AVERAGE_MSRP.value - 1))
    );
  },

  maintenanceCostPerElectricMile: function(vehicle) {
    return (
      this.maintenanceCostPerGasMile(vehicle) *
      (1 - ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR.value)
    );
  },

  perMile: function(vehicle, electricMilesPortionForPhev) {
    if (!vehicle) return 0;

    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";

    let cost = this.maintenanceCostPerGasMile(vehicle); // ICE cost
    if (["BEV"].includes(fuelType.toUpperCase()))
      cost = this.maintenanceCostPerElectricMile(vehicle);
    if (["PHEV"].includes(fuelType.toUpperCase())) {
      cost =
        (electricMilesPortionForPhev / 100) *
          this.maintenanceCostPerElectricMile(vehicle) +
        (1 - electricMilesPortionForPhev / 100) *
          this.maintenanceCostPerGasMile(vehicle);
    }

    return isFinite(cost) ? cost : 0;
  }
};

export default MaintenanceCost;
