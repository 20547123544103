
 const getOEMBadge = oem =>
  {    
    switch ( (oem == null) ? "":  oem.toLowerCase()) {
      case 'audi':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Audi.png';
      case 'bmw':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/BMW.png';
      case 'cadillac':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Cadillac.png';
      case 'chevrolet':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Chevrolet.png';
      case 'chrysler':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Chrysler.png';
      case 'fiat':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Fiat.png';
      case 'ford':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Ford.png';
      case 'honda':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Honda.png';
      case 'hyundai':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Hyundai.png';
      case 'jaguar':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Jaguar.png';
      case 'karma':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Karma.png';
      case 'kia':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Kia.png';
      case 'mini':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Mini.png';
      case 'mercedes-benz':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Mercedes-Benz.png';
      case 'mitsubishi':
          return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Mitsubishi.png';
      case 'nissan':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Nissan.png';
      case 'porsche':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Porsche.png';
      case 'polestar':
          return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Polestar.png';
      case 'smart':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Smart.png';
      case 'subaru':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Subaru.png';
      case 'tesla':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Tesla.png';
      case 'toyota':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Toyota.png';
      case 'volkswagen':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Volkswagen.png';
      case 'volvo':
        return 'https://s3-us-west-1.amazonaws.com/zappyassets/img/oems/Volvo.png';
      default:
        return '';
    }
  }
  export default getOEMBadge;