import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"

import sortEVs from "../../functions/vehicle/Sort/sortEVs";
// import filterEVs from "../../../functions/vehicle/Filter/filterEVs";
import filterInventory from "../../functions/vehicle/Filter/filterInventory.js"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner"
import InventoryEVCard from "../InventoryEVCard/InventoryEVCard"

const InventoryCatalog = ({ vehicles }) => {
  const userPrefs = useContext(UserPrefsContext);

  if (!vehicles) return <LoadingSpinner />;

  const filteredElectricVehicles = filterInventory(vehicles, {
    vehicleMakeFilter: userPrefs.get("vehicleMakeFilter"),
    vehicleModelFilter: userPrefs.get("vehicleModelFilter"),
    inventoryDealers: userPrefs.get("inventoryDealers"),
    vehicleCondition: userPrefs.get("vehicleCondition"),
    vehicleAgeFilter: userPrefs.get("vehicleAgeFilter")
  });

  const filteredAndSortedEvs = sortEVs(
    filteredElectricVehicles,
    userPrefs.get("vehicleSortType"),
    userPrefs.get("vehicleSortDirection"),
    userPrefs.get("milesDrivenDaily"),
    userPrefs.get("chargerLevel"),
    userPrefs.get("maxBudget"),
    userPrefs.get("minSeats"),
    userPrefs.get("salesTax"),
    null,
    null,
    null,
    true
  );

  const renderCards = () => {
    const evs = filteredAndSortedEvs.length ? filteredAndSortedEvs : vehicles
    return evs.map((ev, index) => {
      return (
          <InventoryEVCard key={index} ev={ev} />
      );
    });
  }

  return (
    <>
      {!vehicles.length ?  
        <div className="">
          <LoadingSpinner />
        </div>
        :
        filteredAndSortedEvs.length === 0 ? 
          <div className="available-nearby">
            <div className="no-matches text-center">
              <h2>We didn't find any exact matches.</h2>
              <p className="h4">No vehicles matching your current search criteria can be found in local inventory. Try changing your search criteria</p>
            </div>
            <p>Check out these other EVs available near you!</p>
          </div>
        :
        null
      }
      <div className="render-cards-container flex-column  ev-max-width">{renderCards()}</div>
    </>
  );
};

export default InventoryCatalog
;

InventoryCatalog
.propTypes = {
  vehicles: PropTypes.array,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinktoEv: PropTypes.bool
};
