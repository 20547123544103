import { GASOLINE_PRICE_IN_DOLLARS_PER_GAL } from "../../../../client_customizations/data/assumptions/ASSUMPTIONS"

const GasolineCost = {
  perMile: function(vehicle, electricMilesPortionForPhev) {
    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";

    if (["BEV"].includes(fuelType.toUpperCase())) return 0;

    let mpg =
      vehicle && vehicle.fossil_fuel_efficiency && parseFloat(vehicle.fossil_fuel_efficiency) > 0
        ? parseFloat(vehicle.fossil_fuel_efficiency)
        : 0;

    const PHEVFuelSplitAdjustment =
      ["PHEV"].includes(fuelType.toUpperCase()) &&
      parseFloat(electricMilesPortionForPhev / 100) > 0
        ? 1 - electricMilesPortionForPhev / 100
        : 1;

    let cost =
      GASOLINE_PRICE_IN_DOLLARS_PER_GAL.value *
      (1 / mpg) *
      PHEVFuelSplitAdjustment;

    return isFinite(cost) ? cost : 0;
  }
};

export default GasolineCost;
