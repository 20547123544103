import React, { useContext }from "react";
import PropTypes from "prop-types";
import "./DealerLocationCard.scss";
import getOEMBadge from "../../../functions/vehicle/getOEMBadge";
import contactIcon from "../../assets/images/icons/phoneIcon.svg"
import mapIcon from "../../assets/images/icons/mapIcon.svg"
import websiteIcon from "../../assets/images/icons/websiteIcon.svg"
import { Link } from "react-router-dom";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage } from "react-intl";


const DealerCard = props => {
  let location = props.dealerLocation || {};
  let cityStateAddress = `${location.city}, ${location.state} ${location.postcode}`;
  const userPrefs = useContext(UserPrefsContext);
  const inventoryDealers = userPrefs.get("inventoryDealers")

  const handleClick = (dealerId) => {
    let newFilters = {...inventoryDealers}
    Object.keys(newFilters).forEach(id => newFilters[id] = false)
    newFilters[dealerId] = true
    userPrefs.set({inventoryDealers : newFilters})
  }

  const gmap = {
    "audi-south-austin-austin-tx" : "https://g.page/AudiSAustin?share",
    "autonation-chevrolet-west-austin-austin-tx" : "https://goo.gl/maps/aP9QAVCKwztreZk46",
    "don-hewlett-chevrolet-buick-inc-georgetown-tx" : "https://g.page/DonHewlettChevroletBuick?share",
    "autonation-toyota-south-austin-austin-tx" : "https://goo.gl/maps/TF6iJ8FCsD2KAUL49",
    "hewlett-volkswagen-georgetown-tx" : "https://g.page/HewlettVW?share",
    "town-north-nissan-tx": "https://goo.gl/maps/AL9XYcTcWRLzeFMk8",
    "bmw-of-south-austin" : "https://goo.gl/maps/xD67jwQgbiQVJpEE6",
    "capitol-chevrolet-inc-austin-tx" :"https://goo.gl/maps/3GrD4tgxwE4cPPDM7",
    "henna-chevrolet-lp-austin-tx" : "https://g.page/hennachevrolet?share",
    "volvo-cars-of-austin-austin-tx" : "https://g.page/VolvoAustin?share",
    "polestar-austin-tx" : "https://g.page/polestaraustin?share",
  }
  
  return (
    <div className="DealerLocationCard col-xl-3 col-lg-4 col-md-5 col-sm-6 col-xs-12 row">
      <span className="badge-oem-type">
        {location.oem !== null &&
           <img alt="All-Electric" height="60" src={getOEMBadge(location.oem)} />
        }
      </span>
      <p className="h2">
          {location.name}
        </p>
      <p className="mt-1">
        {location.street} <br/>
        {cityStateAddress}
      </p>
      <Link to="/inventory" className="btn btn-ae shop-button" role="button" onClick={() => handleClick(location.dealer_id)}>
        <FormattedMessage
          id="shopLocal"
          defaultMessage="Shop Local EVs"
          description="Shop Local EVs Header"
        />
      </Link>

      <div className="contactRow">
        <div>
          <a href={`tel: ${location.phone}`}>
            <img src={contactIcon} alt="telephone"/>
            <span>Contact</span>
          </a>
        </div>
        <div>
          <a href={gmap[location.dealership_handle]} target="_blank" rel="noopener noreferrer">
            <img src={mapIcon} alt="pin"/>
            <span>Map</span>
          </a>
        </div>
        <div className="contact">
          <a href={`${location.website}`} target="_blank" rel="noopener noreferrer">
            <img src={websiteIcon} alt="link chain"/>
            <span>Website</span>
          </a>
        </div>
      </div>
    </div>
  );
};

DealerCard.propTypes = {
  dealerLocation: PropTypes.object
};

export default DealerCard;
 