import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import "./SampleCustomPage.scss"
import factsMetaImage  from  "../../assets/images/factsMetaImage.png";


const SampleCustomPage =  ({ ip, uuid, props}) => {
  const intl = useIntl()
  const factsBrowseLinkText = intl.formatMessage({ id: "factsBrowseLink", defaultMessage: "See what EV drivers are saying."});

    return (
      <PageLayout
        ip={ip}
        uuid={uuid}
        props={props}
        description="Sample Custom Page"
        page="EV Facts - Austin Energy EV Buyer's Guide"
        image={factsMetaImage}
      >
        <section className="container" aria-label="EV Facts Page">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-center">
                <h1>
                  <FormattedMessage
                    id="evFacts"
                    defaultMessage="EV Facts"
                    description="EV Facts"
                  />
                </h1>
                <p className="lead">
                  <FormattedMessage
                    id="factsSubheading"
                    defaultMessage="Electric vehicles are easy! Here are five fun facts about EVs."
                    description="Electric vehicles are easy! Here are five fun facts about EVs. EV Facts"
                  />
                </p>
              </div>

              <div className="row my-5 d-flex">
                <div className="col-sm-6 order-sm-2">
                  <div className="youtube-responsive-container">
                    <iframe
                      title="StEVie & The Rexies Garage Band"
                      src="https://www.youtube.com/embed/xOrwpQVBKtw"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className="col-sm-6 order-sm-1 d-flex align-items-center">
                  <div className="text-center px-5">
                    <h2 className="text-uppercase mt-4">
                      <FormattedMessage
                        id="factsUseHeading"
                        defaultMessage="EASY TO USE"
                        description="EASY TO USE EV Facts"
                      />
                    </h2>
                    <p className="lead">
                      <FormattedMessage
                        id="factsUseContent"
                        defaultMessage="Plug into a regular outlet at home or use a charging station for a faster charge."
                        description="Plug into a regular outlet at home or use a charging station for a faster charge. EV Facts"
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="row my-5">
                <div className="col-sm-6">
                  <div className="youtube-responsive-container">
                    <iframe
                      title="StEVie Knows The Science of Savings"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/5eZjeKej7tI"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="text-center px-5">
                    <h2 className="text-uppercase mt-4">
                      <FormattedMessage
                        id="factsMaintain"
                        defaultMessage="EASY TO MAINTAIN"
                        description="EASY TO MAINTAIN EV Facts"
                      />
                    </h2>
                    <p className="lead">
                      <FormattedMessage
                        id="factsMaintainContent"
                        defaultMessage="Enjoy more time and money in your pocket since EVs require less maintenance and have fewer moving parts."
                        description="Enjoy more time and money in your pocket since EVs require less maintenance and have fewer moving parts. EV Facts"
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="row my-5 d-flex">
                <div className="col-sm-6 order-sm-2">
                  <div className="youtube-responsive-container">
                    <iframe
                      title="StEVie the EV-Loving T-Rex"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/obz_FfGkHw8"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className="col-sm-6 order-sm-1 d-flex align-items-center">
                  <div className="text-center px-5">
                    <h2 className="text-uppercase mt-4">
                      <FormattedMessage
                        id="factsDriveEasy"
                        defaultMessage="EASY TO DRIVE"
                        description="EASY TO DRIVE EV Facts"
                      />
                    </h2>
                    <p className="lead">
                      <FormattedMessage
                        id="factsDrivePerformance"
                        defaultMessage="Get the high performance you want in a car, when you choose to lease, buy or get a used EV."
                        description="Get the high performance you want in a car, when you choose to lease, buy or get a used EV. EV Facts"
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="row my-5 d-flex">
                <div className="col-sm-6 order-sm-1">
                  <div className="youtube-responsive-container">
                    <iframe
                      title="StEVie Loves Low Maintenance"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/PFLxEzBHY_I"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className="col-sm-6 order-sm-2 d-flex align-items-center">
                  <div className="text-center px-5">
                    <h2 className="text-uppercase mt-4">
                      <FormattedMessage
                        id="factsDriveEasy"
                        defaultMessage="EASY TO DRIVE"
                        description="EASY TO DRIVE EV Facts"
                      />
                    </h2>
                    <p className="lead">
                      <FormattedMessage
                        id="factsBrowse"
                        defaultMessage="Browse the new {website} Guide to find your vehicle now! {link}"
                        description="Facts Browser EV Facts"
                        values={{
                          website: (
                            <Link to="/">Austin Energy Buyer's Guide</Link>
                          ),
                          link: (
                            <a
                              href="https://austinenergy.com/ae/green-power/plug-in-austin/why-drive-ev/ev-driver-testimonials"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {factsBrowseLinkText}
                            </a>
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    );
};
export default SampleCustomPage;

SampleCustomPage.propTypes = {
    ip: PropTypes.string,
    uuid: PropTypes.string,
    props: PropTypes.object
  };