import { useEffect } from "react";
import PropTypes from "prop-types";

const Custom = ({documentTitle, PageContentFunction }) => {
  useEffect(() => {
    document.title = documentTitle;
  });

  return (PageContentFunction());
};

export default Custom;

Custom.propTypes = {
  documentTitle: PropTypes.string,
  PageContentFunction: PropTypes.func
};
