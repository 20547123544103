import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ChargingMap from "../../client_customizations/components/LocationMap/ChargingMap";

const MapPage = ({ chargingStations, userLocation, ip, uuid }) => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_PAGES_CHARGING_TITLE} - Austin Energy EV Buyers Guide`;
  });

  return (
    <>
      <h1 className="hide-offscreen">Map</h1>
      <ChargingMap
        chargingStations={chargingStations}
        userLocation={userLocation}
      />
    </>
  );
};

export default MapPage;

MapPage.propTypes = {
  chargingStations: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
