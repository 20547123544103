import React, { useEffect } from "react";
import PropTypes from "prop-types";

import ERidesBigPromise from "../../client_customizations/components/ERides/components/ERidesBigPromise";
import ERidesRebateCards from "../../client_customizations/components/ERidesRebateCards/ERidesRebateCards";
import AustinBikeMap from "../../client_customizations/components/AustinBikeMap/AustinBikeMap";
import MetroBikeAccess from "../../client_customizations/components/MetroBikeAccess/MetroBikeAccess";

const ERides = ({
  eRidesBannerImage,
  ip,
  uuid,
}) => {
  

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_TITLE;

  });

  const renderDetails = (
    <>
      <ERidesRebateCards />
      <AustinBikeMap />
      <MetroBikeAccess />
    </>
  );


  return (
    <>
      <ERidesBigPromise eRidesBannerImage={eRidesBannerImage} />
      {renderDetails}
    </>
  );
};

export default ERides;

ERides.propTypes = {
  eRidesBannerImage: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
