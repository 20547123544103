import React, { useContext } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"
import SelectVehicleMakeFilter from "../InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter"
import SelectVehicleAgeFilter from "../InputComponents/SelectVehicleAgeFilter/SelectVehicleAgeFilter"
import SelectVehicleModelFilter from "..//SelectVehicleModelFilter/SelectVehicleModelFilter"
import PropTypes from "prop-types";
import "./InventoryFilterControls.scss"


const InventoryFilterControls = ({vehicles}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();
  const vehicleCondition = userPrefs.get("vehicleCondition")

  const allFilter = intl.formatMessage ? intl.formatMessage({ id: "inventoryAll", defaultMessage: "All"}) : "All";
  const newFilter = intl.formatMessage ? intl.formatMessage({ id: "inventoryNew", defaultMessage: "New"}) : "New";
  const usedFilter = intl.formatMessage ? intl.formatMessage({ id: "inventoryUsed", defaultMessage: "Used"}) : "Used";

  const vehicleConditions = [allFilter, newFilter, usedFilter];
  const renderVehicleConditions = vehicleConditions.map(vc => {
    return (
      <button
        className={`ev-condition-button ${vehicleCondition === vc ? "active" : null}`}
        key={vc}
        type="button"
        value={vc}
        onClick={(e) =>
        userPrefs.set({ vehicleCondition: vc,  vehicleAgeFilter: "All"})
      }
      >
        {vc}
      </button>
    )
  })

  return (
    <div className="input-well inventory-filter-controls">
      <p className="h2"><FormattedMessage 
              id="inventoryFilter"
              defaultMessage="Filter Vehicles"
              description="Filter Vehicles Title"
            /></p>
      <form>
        <div className="d-flex justify-content-between">
          {renderVehicleConditions}
        </div>

        <div className="form-group">
          <SelectVehicleMakeFilter vehicles={vehicles} />
          <SelectVehicleModelFilter vehicles={vehicles} />

        </div>
        <div className="form-group">
          <SelectVehicleAgeFilter />
        </div>
      </form>
    </div>
  );
};

export default InventoryFilterControls;

InventoryFilterControls.propTypes = {
  vehicles: PropTypes.array
};