import React from "react";
import PropTypes from "prop-types";
import LinkCard from "../../LinkCard/LinkCard"
import {FormattedMessage} from 'react-intl';
import { NavLink } from "react-router-dom";

import  SHOP_VEHICLES from "../../../assets/images/icons/browse-electric-vehicles.svg"
import DISCOVER_INCENTIVES from "../../../assets/images/icons/discover-incentives.svg";
import LOCATE_CHARGING_STATIONS from "../../../assets/images/icons/locate-charging-stations.svg";
import FIND_DEALERS from "../../../assets/images/icons/find-dealers.svg"
import BROWSE_VEHICLES from "../../../assets/images/icons/magnify.svg"
import bike from "../../../assets/images/icons/bike.svg"


const HomepageBigPromise = ({
  homePageBannerImage
}) => {

  const linkCards = [
    { title: <FormattedMessage 
      id="homepage.linkCard.browseElectricVehicles"
      defaultMessage="BROWSE ELECTRIC {lineBreak} VEHICLES"
      description="Home Card Title"
      values= {{
        lineBreak: <br />,
      }}
      />, 
      image: SHOP_VEHICLES, 
      alt: "Magnifying Glass", 
      link:"/inventory",
      key: "Shop Local Electric Vehicles"
    },
    { title: <FormattedMessage 
      id="homepage.linkCard.researchElectricVehicles"
      defaultMessage="RESEARCH ELECTRIC {lineBreak} VEHICLES"
      description="Home Card Title"
      values= {{
        lineBreak: <br />,
      }}
      />, 
      image: BROWSE_VEHICLES, 
      alt: "Electric Vehicle", 
      scrollTo: "HomepageVehiclesCarousel",
      key: "Browse Electric Vehicles"
    },
    { title: <FormattedMessage 
      id="homepage.linkCard.discoverIncentives"
      defaultMessage="DISCOVER {lineBreak} INCENTIVES"
      description="Home Card Title"
      values= {{
        lineBreak: <br />,
      }}
      />, 
      image: DISCOVER_INCENTIVES, 
      alt: "Money Bag", 
      scrollTo: "HomepageIncentives",
      key: "Discover Incentives"
    },
    { title: <FormattedMessage 
      id="homepage.linkCard.locateChargingStations"
      defaultMessage="LOCATE CHARGING {lineBreak} STATIONS"
      description="Home Card Title"
      values= {{
        lineBreak: <br />,
      }}
      />, 
      image: LOCATE_CHARGING_STATIONS, 
      alt: "Charging Station", 
      scrollTo: "HomepageChargingStations",
      key: "Locate Charging Stations"
    },
    { title: <FormattedMessage 
      id="homepage.linkCard.findDealers"
      defaultMessage="FIND {lineBreak} DEALERS"
      description="Home Card Title"
      values= {{
        lineBreak: <br />,
      }}
      />, 
      image: FIND_DEALERS, 
      alt: "Car Key", 
      link: "/dealers",
      key: "Find Dealers"
    },
    ]
  
  const renderLinkCards = linkCards.map(lc => <LinkCard title={lc.title} image={lc.image} alt={lc.alt} scrollTo={lc.scrollTo} link={lc.link} key={lc.key}/>)

  return (
    <section className="container p-0" aria-label="Homepage Big Promise">
      <div className="big-promise" style={{ position: "relative" }}>
        <div className="gradient-top-left" />

        <div
          style={{
            backgroundImage: "url(" + homePageBannerImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "475px",
            boxSizing: "border-box",
          }}
        >
        </div>

        <div>
          <h1 style={{ display: "none" }}>Welcome to EV Buyers Guide</h1>
        </div>
      </div>

      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="link-card-container">{renderLinkCards}</div>
          </div>
        </div>
      </div>
      <div className="eRides-link">
        <NavLink to="/e-ride" className="eRides-button">
          <img className="eRides-bike" alt="bike" src={bike} />
          <div className="eRides-text">
            <FormattedMessage
              id="homepage.linkCard.eRides"
              defaultMessage="Check out {ERides} to access rebates for electric bikes and more"
              description="ERides Link"
              values={{
                ERides: (
                  <>
                    {" "}
                    <strong className="underline"> E-Ride page </strong>
                    {" "}
                  </>
                ),
              }}
            />
          </div>
        </NavLink>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}