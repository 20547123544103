import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { TabContent, TabPane, Nav } from "reactstrap";

import calcEmissions from "./../../../functions/vehicle/Emissions/calcEmissions";
import isPHEV from "../../../functions/vehicle/isPHEV";

import {
  FormatCarName,
  FormatAsInt,
  FormatAsThousands,
  FormatAsPercentRounded,
  FormatAsTime
} from "./../../../utils/Helpers/Format";
import {
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL
} from "../../../client_customizations/data/assumptions/ASSUMPTIONS"
import calcCharging from "../../../functions/vehicle/Charging/calcCharging";

const CarDetailsCalcs = ({ car }) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => setActiveTab(tab);

  const userPrefs = useContext(UserPrefsContext);
  const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");
  const electricMilesPortionForPhev = userPrefs.get(
    "electricMilesPortionForPhev"
  );

  const carIsPHEV = isPHEV(car);
  const equivalentGasVehicle = car.equivalent_gas_vehicle || {};
  const comparisonText = (
    <p className="text-left">
      We selected the {FormatCarName(equivalentGasVehicle)} based on its
      proximity to the {FormatCarName(car)} based on brand, size, and price.
    </p>
  );

  return (
    <div className="CalcPanels">
      <Nav className="nav-fill">
        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "1" ? "active" : ""
            }`}
            onClick={() => toggle("1")}
          >
            <span>Charging</span>
          </button>
        </li>

        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "2" ? "active" : ""
            }`}
            onClick={() => toggle("2")}
          >
            <span>Fuel</span>
          </button>
        </li>

        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "3" ? "active" : ""
            }`}
            onClick={() => toggle("3")}
          >
            <span>Emissions</span>
          </button>
        </li>
      </Nav>
      <TabContent activeTab={activeTab}>
        <br />
        <TabPane tabId="1">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">Source</th>
              </tr>
            </thead>
            <tbody>
              {!carIsPHEV && (
                <>
                  <tr>
                    <th scope="row">DC Fast Charging (DCFC) Rate</th>
                    <td>{calcCharging.speedInKw("dc_fast_charger")} kW</td>
                    <td />
                  </tr>
                  <tr>
                    <th scope="row">kWh per 100 mi</th>
                    <td>{car.electric_efficiency} kWh</td>
                    <td>EPA Efficiency Rating</td>
                  </tr>
                  <tr>
                    <th scope="row">Miles Per 30 min of Fast Charging</th>
                    <td>
                      ~{" "}
                      {FormatAsInt(
                        calcCharging.milesPerHalfHourOfCharge(
                          car.electric_efficiency,
                          "dc_fast_charger"
                        )
                      )}{" "}
                      miles
                    </td>
                    <td>Charging Rate / (kWh per 100 mi / 100) / 2</td>
                  </tr>
                </>
              )}
              <tr>
                <th scope="row">Vehicle Battery Capacity</th>
                <td>{car.battery_capacity} kWh</td>
                <td />
              </tr>
              <tr>
                <th scope="row">Vehicle Max AC Intake</th>
                <td>{car.ac_charging_power} kW</td>
                <td />
              </tr>
              <tr>
                <th scope="row">Level 2 Charging Rate</th>
                <td>{calcCharging.speedInKw("level_2")} kW</td>
                <td />
              </tr>
              <tr>
                <th scope="row">Charging Rate</th>
                <td>
                  {Math.min(
                    calcCharging.speedInKw("level_2"),
                    car.ac_charging_power
                  )}{" "}
                  kW
                </td>
                <td>
                  Minimum of Level 2 Charging Rate and Vehicle Max AC Intake
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">Time to Full Charge for Level 2</th>
                <td>
                  ~{" "}
                  {FormatAsTime(
                    calcCharging.timeForFullBattery(
                      car.battery_capacity,
                      car.ac_charging_power,
                      "level_2"
                    )
                  )}
                </td>
                <td>Vehicle Battery Capacity / Charging Rate</td>
              </tr>
            </tfoot>
          </table>
        </TabPane>
        <TabPane tabId="2">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">{FormatCarName(equivalentGasVehicle)}</th>
                <th scope="col">Source</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Miles driven per year</th>
                <td colSpan="2">{FormatAsThousands(milesDrivenAnnually)}</td>
                <td>User input</td>
              </tr>
              <tr>
                <th scope="row">Miles Per Gallon (MPG)</th>
                <td>
                  {carIsPHEV && car.fossil_fuel_efficiency + " miles/gal"}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{equivalentGasVehicle.fossil_fuel_efficiency + " miles/gal"}</td>
                <td />
              </tr>
              {carIsPHEV && (
                <tr>
                  <th scope="row">Portion of Electric Driving</th>
                  <td>{FormatAsPercentRounded(electricMilesPortionForPhev)}</td>
                  <td>N/A</td>
                  <td>
                    User input. For a hybrid vehicle, estimates the portion of
                    miles driven using the electric battery instead of gasoline.
                  </td>
                </tr>
              )}
              <tr>
                <th scope="row">Gallons used</th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.gallonsUsed(
                        car,
                        milesDrivenAnnually,
                        electricMilesPortionForPhev
                      )
                    )}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>
                  {FormatAsThousands(
                    calcEmissions.gallonsUsed(
                      equivalentGasVehicle,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  )}
                </td>
                <td>Miles driven * (1 - Portion of Electric Driving) / MPG</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">Gallons Saved</th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.gallonsSaved(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  )}
                </td>
                <td>
                  Gallons used by Equivalent Gas Vehicle - Gallons used by EV
                </td>
              </tr>
            </tfoot>
          </table>
          {comparisonText}
        </TabPane>
        <TabPane tabId="3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">{FormatCarName(equivalentGasVehicle)}</th>
                <th scope="col">Source</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.title}
                </th>
                <td>{ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.valueAsFormatted}</td>
                <td>N/A</td>
                <td />
              </tr>
              <tr>
                <th scope="row">Miles driven per year</th>
                <td colSpan="2">{FormatAsThousands(milesDrivenAnnually)}</td>
                <td>User input</td>
              </tr>
              {carIsPHEV && (
                <tr>
                  <th scope="row">Portion of Electric Driving</th>
                  <td>{FormatAsPercentRounded(electricMilesPortionForPhev)}</td>
                  <td>N/A</td>
                  <td>
                    User input. For a hybrid vehicle, estimates the portion of
                    miles driven using the electric battery instead of gasoline.
                  </td>
                </tr>
              )}
              <tr>
                <th scope="row">kWh per 100 mi</th>
                <td>{car.electric_efficiency} kWh</td>
                <td>N/A</td>
                <td>EPA Efficiency Rating</td>
              </tr>
              <tr>
                <th scope="row">Electric Emissions in Lbs</th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.electricEmissionsInLbs(
                        car.electric_efficiency,
                        milesDrivenAnnually *
                          (electricMilesPortionForPhev / 100)
                      )
                    )}
                  {!carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.electricEmissionsInLbs(
                        car.electric_efficiency,
                        milesDrivenAnnually
                      )
                    )}
                </td>
                <td>N/A</td>
                <td>
                  Electric Emissions in Lbs/MWh *
                  {carIsPHEV && <span>Portion of Electric Driving *</span>}{" "}
                  Miles Driven Per Year * kWh per 100 mi / (100 * 1000)
                </td>
              </tr>
              <tr>
                <th scope="row">{GASOLINE_EMISSIONS_IN_LBS_PER_GAL.title}</th>
                <td>
                  {carIsPHEV &&
                    GASOLINE_EMISSIONS_IN_LBS_PER_GAL.valueAsFormatted}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{GASOLINE_EMISSIONS_IN_LBS_PER_GAL.valueAsFormatted}</td>
                <td>
                  <a
                    href={GASOLINE_EMISSIONS_IN_LBS_PER_GAL.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    U.S. Energy Information Administration
                  </a>
                </td>
              </tr>
              <tr>
                <th scope="row">Miles Per Gallon (MPG)</th>
                <td>
                  {carIsPHEV && car.fossil_fuel_efficiency + " miles/gal"}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{equivalentGasVehicle.fossil_fuel_efficiency + " miles/gal"}</td>
                <td />
              </tr>
              <tr>
                <th scope="row">Gasoline Emissions in lbs of CO2/yr</th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.gasolineEmissionsInLbs(
                        car.fossil_fuel_efficiency,
                        milesDrivenAnnually *
                          (1 - electricMilesPortionForPhev / 100)
                      )
                    )}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>
                  {FormatAsThousands(
                    calcEmissions.gasolineEmissionsInLbs(
                      equivalentGasVehicle.fossil_fuel_efficiency,
                      milesDrivenAnnually
                    )
                  )}
                </td>
                <td>
                  Gasoline Emissions In Lbs per Gallon * Miles Driven Per Year{" "}
                  {carIsPHEV && (
                    <span>* (1 - Portion of Electric Driving) </span>
                  )}
                  / Miles Per Gallon
                </td>
              </tr>
              <tr>
                <th scope="row">Emissions Reductions</th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.reductionInLbs(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  ) + " lbs of CO2 per year"}
                </td>
                <td>Gasoline Emissions in Lbs - Electric Emissions in Lbs</td>
              </tr>
              <tr>
                <th scope="row">{EMISSIONS_PER_TREE_IN_LBS_OF_CO2.title}</th>
                <td colSpan="2">
                  {EMISSIONS_PER_TREE_IN_LBS_OF_CO2.valueAsFormatted}
                </td>
                <td>
                  <a
                    href={EMISSIONS_PER_TREE_IN_LBS_OF_CO2.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Arbor Day Foundation
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">Trees Planted</th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.treesPlanted(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  ) + " trees"}
                </td>
                <td>Emissions Reductions / Emissions Saved per Tree</td>
              </tr>
            </tfoot>
          </table>
          {comparisonText}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CarDetailsCalcs;

CarDetailsCalcs.propTypes = {
  car: PropTypes.object
};
