import React from "react";
import { FormattedMessage, useIntl  } from "react-intl";
import ERidesCard from "../ERidesCard/ERidesCard";
import RidesOne from "../../assets/images/RidesOne.svg";
import RidesTwo from "../../assets/images/RidesTwo.svg";
import RidesThree from "../../assets/images/RidesThree.svg";
import RidesFour from "../../assets/images/RidesFour.svg";
import "./ERidesRebateCards.scss"


const ERidesRebateCards = () => {
  const intl = useIntl();


     const cardInfo = [
       {
        id:"eRides.rebates.oneCard",
         price: "$600",
         title: (
           <FormattedMessage
             id="eRides.rebates.oneTitle"
             defaultMessage="For Individual Customers"
             description="ERides Card One Title"
           />
         ),
         image: RidesOne,
         link: "https://austinenergy.com/green-power/plug-in-austin/more-ways-to-go-electric/e-ride-rebate",
       },
       {
              id:"eRides.rebates.twoCard",
         price: "$1,300",
         title: (
           <FormattedMessage
             id="eRides.rebates.twoTitle"
             defaultMessage="For Customer Assistance Program participants"
             description="ERides Card One Title"
           />
         ),
         image: RidesTwo,
         link: "https://austinenergy.com/green-power/plug-in-austin/more-ways-to-go-electric/e-ride-rebate",
       },
       {
              id:"eRides.rebates.threeCard",
         price: "$800",
         title: (
           <FormattedMessage
             id="eRides.rebates.threeTitle"
             defaultMessage="For Fleet customers purchasing 5-25 E-Rides"
             description="ERides Card One Title"
           />
         ),
         image: RidesThree,
         link: "https://austinenergy.com/green-power/plug-in-austin/more-ways-to-go-electric/e-ride-rebate",
       },
       {
              id:"eRides.rebates.fourCard",
         price: intl.formatMessage({
           id: "eRides.rebates.local",
           defaultMessage: "Local",
         }),
         title: (
           <FormattedMessage
             id="eRides.rebates.localTitle"
             defaultMessage="Purchases must be made through an eligible dealer to qualify"
             description="ERides Card One Title"
           />
         ),
         image: RidesFour,
         link: "https://austinenergy.com/green-power/plug-in-austin/more-ways-to-go-electric/e-ride-rebate",
       },
     ];

const cards = cardInfo.map((card, i) => {
  return (
    <div
      className={`${
        "evc-card EVCard"
      }`}
      key={i}
    >
      <ERidesCard
       id={card.id}
       price={card.price}
       title={card.title}
       image={card.image}
       link={card.link}
      />
    </div>
  );
});

  const renderCards = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex" style={{justifyContent: "center"}}>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {cards}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <section className="container pb-0" id="ERidesRebateCards">
      <div className="row">
        <div className="col-md-12">
          <div className="banner-center">
            <h2 className="eRides-title">
              <FormattedMessage
                id="eRides.rebate.title"
                defaultMessage="Electric Ride (E-Ride) Rebates"
                description="ERides Cards Title"
              />
            </h2>
            <h4 className="eRides-subTitle">
              <FormattedMessage
                id="eRides.rebate.subTitle"
                defaultMessage="Save money with Austin Energy while purchasing your eligible E-bike, scooter, moped, motorcycle, or any other electric two or three-wheel vehicle"
                description="ERides Cards subTitle"
              />
            </h4>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12 eRides-cards">{renderCards}</div>
      </div>
      <div className="row banner-center">
        <div className="eRides-button">
          <a
            href={`https://austinenergy.com/green-power/plug-in-austin/more-ways-to-go-electric/e-ride-rebate`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-orange"
          >
            <FormattedMessage
              id="eRides.rebate.button"
              defaultMessage="See rebate offerings and apply now"
              description="ERides Cards button"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default ERidesRebateCards;


