const fetchHomeChargers = async params => {  
    let url = new URL(
      `${process.env.REACT_APP_EV_INFO_API_HOST}/chargers`
    );

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;
        
    const response = await window.fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
      }
    });
  
    const homeChargers = await response.json();
    return homeChargers;
  };
  
  export default fetchHomeChargers;
  