import React from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import q from "../../../client_customizations/assets/images/icons/icon-q.png";

const ToolTip = ({ id, message }) => {
  return (
    <>
      <span id={id} style={{ display: "inline-block" }}>
        <img
          src={q}
          alt=""
          style={{ width: "16px", marginLeft: "5px", marginBottom: "3px" }}
        />
      </span>
      <UncontrolledTooltip placement="right" target={id}>
        <span>{message}</span>
      </UncontrolledTooltip>
    </>
  );
};

export default ToolTip;

ToolTip.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string
};
