import React, { useState, useRef } from "react";

import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
// import useSWR from 'swr';

import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/charger-pin-public.png";
import ChargingPinHighPower from "./../../../client_customizations/assets/images/icons/charger-pin-high-power.png";
import ChargingPinDefault from "./../../../client_customizations/assets/images/icons/charger-pin-private.png";
import MapStyles from "./MapStyles";


const Map = ({
  chargingStations,
  onHoverOnStation,
  onHoverOffStation,
  userLocation,
  ignoreSuperchargerStations,
  cluster_options //https://github.com/mapbox/supercluster#options

}) => {

  let customDistanceToMouse = (pt, mousePos, markerProps) => {
    const K_SCALE_NORMAL = 0.65;

    const K_MARKER_HEIGHT = 60;
    // marker is more tall at top, so calc distance to some point at marker top
    const K_MARKER_WEIGHT_PT = K_MARKER_HEIGHT * 0.7;
    // distance to markers depends on scale so hover on big markers is more probable
    const scale = markerProps.scale;
    const x = pt.x;
    const y = pt.y - K_MARKER_WEIGHT_PT * scale;

    const scaleNormalized = Math.min(scale / K_SCALE_NORMAL, 1);
    const K_MIN_DIST_MIN_KOEF = 0.6;

    const distKoef = 1 + scaleNormalized * (K_MIN_DIST_MIN_KOEF - 1);
    return (
      distKoef *
      Math.sqrt(
        (x - mousePos.x) * (x - mousePos.x) +
        (y - mousePos.y) * (y - mousePos.y)
      )
    );
  }

  let _onChildMouseEnter = (_key, childProps) => {
    if (onHoverOnStation)
      onHoverOnStation(childProps.station.id || childProps.station.name);
  }

  let _onChildMouseLeave = (/* key, childProps */) => {
    if (onHoverOffStation) {
      onHoverOffStation();
    }
  }
  const center = {
    lat: userLocation
      ? parseFloat(userLocation.latitude)
      : 37.7916858,
    lng: userLocation
      ? parseFloat(userLocation.longitude)
      : -122.397855
  }



  const [zoom, setZoom] = useState(10);

  const iconSrc = type => {
    switch (type) {
      case "public":
        return ChargingPinPublic;
      case "highPower":
        return ChargingPinHighPower;
      case "private":
      default:
        return ChargingPinDefault;
    }
  };

  const K_HOVER_DISTANCE = 30;

  const withinCurrentBounds = (lat, lng) => {
    const [left, bottom, right, top] = bounds;
    if (!top || !bottom || !left || !right) {
      return false;
    }
    if (top >= lat && lat >= bottom) {
      if (left <= right && left <= lng && lng <= right) {
        return true;
      } else if (left > right && (left <= lng || lng <= right)) {
        return true;
      }
    }
    return false;
  }

  chargingStations = chargingStations || [];

  let points = chargingStations.map((station, i) => ({
    type: "Feature",
    station: station,
    properties: { cluster: false, stationId: station.id, category: station.acess_code, name: station.name },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(station.longitude),
        parseFloat(station.latitude)
      ]
    },
    iconType:
      Number(station.ev_dc_fast_num) && !ignoreSuperchargerStations
        ? "highPower"
        : station.access_code === "public"
          ? "public"
          : "default",

    imageStyle: {
      width: "25px"
    },

  }));
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const Marker = ({ children }) => children;
  let clusterOptions =  (cluster_options == null) ? { radius: 40, maxZoom: 0} : cluster_options;


  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: clusterOptions
  });

  return (
    <div style={{ height: "550px", width: "100%" }} className="Map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_ENVIRONMENT === "production" ?  process.env.REACT_APP_PROD_GOOGLE_MAP_API_KEY : process.env.REACT_APP_DEV_GOOGLE_MAP_API_KEY}}
        center={center}
        defaultZoom={zoom}
        options={{ styles: MapStyles }}
        onChildMouseEnter={_onChildMouseEnter}
        onChildMouseLeave={_onChildMouseLeave}
        hoverDistance={K_HOVER_DISTANCE}
        distanceToMouse={customDistanceToMouse}

        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
      >
        {clusters.filter(location => {
          const [longitude, latitude] = location.geometry.coordinates;
          return location.properties.cluster || withinCurrentBounds(latitude, longitude)
        }).map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="google-map-cluster-marker"

                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                ><span className="text"> {pointCount}</span>

                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={`${cluster.properties.stationId || cluster.properties.name}`}
              lat={latitude}
              lng={longitude}
            >
              <button className="google-map-location-marker-button"
                onMouseOver={() => { _onChildMouseEnter(`${cluster.properties.stationId}`, cluster) }}
                onMouseLeave={() => { _onChildMouseLeave() }} >
                <img src={iconSrc(`${cluster.iconType}`)}
                  style={cluster.imageStyle} alt={`${cluster.imageStyle}`} />
              </button>
            </Marker>
          );
        })}
      </GoogleMapReact>
    </div>
  );
}


Map.propTypes = {
  chargingStations: PropTypes.array,
  onHoverOnStation: PropTypes.func,
  onHoverOffStation: PropTypes.func,
  selectedStationId: PropTypes.number,
  userLocation: PropTypes.object,
  ignoreSuperchargerStations: PropTypes.bool,
  zoom: PropTypes.number,
  cluster_options: PropTypes.any
};

export default Map;
