import React from "react";
import PropTypes from "prop-types";

let VehicleImage = ({ image, size, alt }) => {
  if (!image) return null;
  const src = size === "full" ? image.url_full : image.url_thumbnail;
  alt = alt || image["legal_info"];

  return <img src={src} alt={alt} className="img-fluid" />;
};

export default VehicleImage;

VehicleImage.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  size: PropTypes.string
};
