import React from "react";
import PropTypes from "prop-types";

const ERidesBigPromise = ({ eRidesBannerImage }) => {
  


  return (
    <section className="container p-0" aria-label="ERides Big Promise">
      <div className="big-promise" style={{ position: "relative" }}>
        <div
          style={{
            backgroundImage: "url(" + eRidesBannerImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "561px",
            boxSizing: "border-box",
          }}
        />
      </div>
    </section>
  );
};

export default ERidesBigPromise;

ERidesBigPromise.propTypes = {
  eRidesBannerImage: PropTypes.string,
};
