export default {
    "home": "Inicio",
    "shopLocal" : "Compre VE locales",
    "shopDealers" : "Distribuidores de tiendas",
    "viewVehicleDetails" : "Ver detalles del vehículo",
    "browseInventory" : "Explorar inventario cercano",
    "dealerDirectory" : "Directorio de distribuidores",
    "evFacts" : "Hechos de los VE",
    "faq" : "Preguntas Frecuentes",
    "electricVehicles": "Autos Eléctricos",
    "usedElectricVehicles" : "Autos Eléctricos Usados",
    "compareVehicles": "Compare Vehículos",
    "incentives" : "Incentivos",
    "chargingStations" : "Estaciones de Carga",
    "homeChargers" : "Cargadores Caseros",
    "dealers" : "Distribuidores",
    "newVehicles" : "Autos Nuevos",
    "electricians" : "Electricistas",

    "electricity" : "Fuente",
    "gasoline" : "Gasolina",
    "total" : "Total",
    "description" : "Descripción",
    "detailedCalculations" : "detalles del cálculo",
    "charging" : "Carga",
    "emissions" : "Emisiones",
    "source" : "Fuente",
    "vehicles" : "Vehículos",
    "make" : "Marca",
    "model" : "Modelo",
    "all" : "Todos",
    "age" : "Años",
    "zipcode" : "Código Postal",

    "dealersHeading" : "Concesionarios",
    "dealersSubheading" : "Los concesionarios que aparecen a continuación trabajan en estrecha colaboración con Austin Energy para ofrecer una gran experiencia de compra de vehículos eléctricos. Contacte y visite estos concesionarios con la confianza de que le ayudarán a elegir el vehículo adecuado para usted.",

    "personalizeIncentives" : "Personalizar Incentivos",

    "factsSubheading" : "¡Los vehículos eléctricos son fáciles! He aquí cinco datos curiosos sobre los vehículos eléctricos.",
    "factsUseHeading" : "FÁCIL DE USAR",
    "factsUseContent" : "Enchúfelo en una toma de corriente normal en casa o utilice una estación de carga para una carga más rápida.",
    "factsMaintain" : "FÁCIL DE MANTENER",
    "factsMaintainContent" : "Disfrute de más tiempo y dinero en su bolsillo, ya que los vehículos eléctricos requieren menos mantenimiento y tienen menos piezas móviles.",
    "factsCharge" : "FÁCIL DE CARGAR",
    "factsChargeContent" : "Cargue por sólo {amount} al mes en cualquiera de los más de 800 puertos de carga locales de Austin Energy, alimentados por energía eólica 100% renovable de Texas.",
    "factsDriveEasy" : "FÁCIL DE CONDUCIR",
    "factsDrivePerformance" : "Obtenga las altas prestaciones que desea en un automóvil, cuando decida arrendar, comprar o conseguir un VE usado.",
    "factsDriveContent" : "¡FÁCIL DE ENCONTRAR!",
    "factsBrowse" : "¡Navegue por la nueva Guía del Comprador de {website} para encontrar su vehículo ahora! {link}",
    "factsBrowseLink" : "Vea lo que dicen los conductores de vehículos eléctricos.",

    "faqHeading" : "Preguntas frecuentes sobre los VE de Austin Energy",
    "faqVehicles" : "Vehículos",
    "faq1" : "¿Qué es un vehículo eléctrico?",
    "faq1AA" : "Un vehículo eléctrico es cualquier vehículo que pueda circular con electricidad. Si puede enchufarlo para cargarlo, es un vehículo eléctrico. Un vehículo totalmente eléctrico (a veces llamado vehículo eléctrico de batería o (BEV) funciona únicamente con electricidad y no utiliza gasolina. {link} Existen dos tipos de vehículos eléctricos:",
    "faq1A-link" : "¿Qué tipos de vehículos eléctricos existen?",
    "faq1A-1" : "Vehículo eléctrico de batería (BEV): depende exclusivamente de una batería para impulsar el automóvil. ¡Despídase de las bombas de gasolina!",
    "faq1A-2" : "Vehículos eléctricos híbridos enchufables (PHEV): utilizan tanto electricidad como gasolina. Al conducir, utilizan la energía de la batería y cambian a la gasolina cuando la batería se agota.",
    "faq2" : "¿Por qué debería conducir un VE?",
    "faq2A" : "Fáciles de usar, de mantener y de cargar, los vehículos eléctricos son divertidos de conducir y pueden ahorrarle dinero a la vez que ayudan al medio ambiente. Los vehículos eléctricos son más baratos, más rápidos y más limpios de operar, además de tener unos costos de mantenimiento mucho más bajos. Si usted es como la mayoría de la gente que se desplaza unos 50 kilómetros al día, un VE puede significar más dinero en su cuenta bancaria y más tiempo para disfrutar de su vida.",
    "faq3" : "¿Qué debo tener en cuenta antes de comprar un vehículo eléctrico?",
    "faq3AA" : "Al igual que la selección de un automóvil de gasolina, la elección del vehículo eléctrico más adecuado para usted depende de diferentes factores, como sus hábitos de conducción y sus preferencias personales. He aquí algunos factores específicos de los VE que hay que tener en cuenta:",
    "faqTotalRange" : "Alcance total:",
    "faqSavings" : "Ahorro:",
    "faq3A-1" : "{totalRange} ¿Hasta dónde va a viajar? La autonomía total de los vehículos eléctricos actuales varía mucho: entre 40 y 350 millas.",
    "faq3A-2" : "{charging} ¿Dónde va a cargar? A dónde conduce y la forma en la que cargará su vehículo pueden ayudarle a decidir qué vehículo eléctrico se ajustará a sus necesidades.",
    "faq3A-3" : "{savings} ¿Con qué frecuencia requiere mantenimiento su automóvil de gasolina? ¿Con qué frecuencia llena el depósito de gasolina? Los conductores de vehículos eléctricos ahorran en combustible y en el mantenimiento del automóvil, lo que también ahorra tiempo y ayuda al medio ambiente.",
    "faq3A-4" : " Si su trayecto diario es inferior a 40 millas, prácticamente todos los vehículos eléctricos podrán hacer frente a su conducción diaria sin necesidad de combustible. Si quiere tener la capacidad de conducir mucho más lejos, varios vehículos totalmente eléctricos pueden recorrer de 100 a más de 300 millas con una carga. Para los raros viajes por carretera, el trayecto puede hacerse parando en las estaciones de carga de camino a su destino.",
    "faq4" : "¿Qué tan rápidos son los VE?",
    "faq4A" : "¡Rápidos! ¡Cuando se pisa el acelerador, los VE son rápidos! Los VE proporcionan una potencia instantánea (par motor), lo que supone una aceleración más rápida que la de cualquier automóvil de gasolina que haya tenido antes, lo que hace que los VE sean muy divertidos de conducir. Con un motor eléctrico, la experiencia de conducción es también más silenciosa, y a menudo más agradable, que la de un automóvil tradicional de gasolina.",
    "faq5" : "¿Qué mantenimiento requieren los vehículos eléctricos?",
    "faq5AA" : "Con un VE, no hay cambios de aceite, ni bujías, ni correas de distribución. No hay más arreglos costosos con el tiempo.{br} Como hay muchas menos piezas móviles en un VE en comparación con un vehículo tradicional, se necesita menos mantenimiento preventivo continuo (sólo rotaciones de neumáticos). Como no hay escape, tampoco hay silenciador ni catalizador. Eso significa más dinero en su bolsillo.{br} Los frenos también duran más en los VE. Cuando se levanta el pie del acelerador, el motor eléctrico frena el automóvil. Incluso utiliza la energía extra y la almacena de nuevo en la batería dando a su VE un poco más de autonomía.{br} Además, todos los fabricantes de automóviles ofrecen al menos 8 años de garantía para las baterías, si no más.",
    "faq6" : "¿Son las baterías de los vehículos eléctricos mejores que el uso de la gasolina en los automóviles?",
    "faq6A" : "En muchos aspectos, sí. A diferencia de la gasolina, las baterías de los vehículos eléctricos no emiten emisiones nocivas de carbono a la atmósfera para contaminar el aire y el agua. Además, una vez que las baterías ya no pueden utilizarse para los automóviles, pueden reutilizarse para el almacenamiento de energía. Algunas empresas automovilísticas ya están utilizando bloques de baterías de vehículos eléctricos antiguos para alimentar edificios. Al final de su vida útil, también son reciclables.",
    "faq7" : "¿Son los vehículos eléctricos realmente mejores para el medio ambiente?",
    "faq7A" : "Los vehículos eléctricos no producen gases de escape, no hay sustancias químicas desagradables que contribuyan al smog u otras formas de contaminación del aire o del agua. El cambio de los automóviles a los eléctricos mejorará drásticamente la calidad del aire local, ahorrará millones en costos sanitarios por enfermedades respiratorias y reducirá los efectos a largo plazo del cambio climático.",
    "faq8" : "¿Qué es un automóvil ICE?",
    "faq8A" : "Es posible que oiga o vea el término 'ICE' cuando se refiere a un vehículo de gas. 'ICE' son las siglas de 'Internal Combustion Engine' (motor de combustión interna). Además de contribuir a los gases nocivos de efecto invernadero, estos automóviles requieren más mantenimiento y tienen más piezas móviles que los vehículos eléctricos.",
    "faqCharging" : "Cargando",
    "faq9" : "¿Dónde puedo cargar mi VE?",
    "faq9A-1" : "¿Tiene una casa? Si tiene un garaje o una entrada, puede cargar en casa, de modo que puede salir cada mañana con el 'tanque lleno'. Con la autonomía de la mayoría de los VE, ¡ni siquiera es necesario cargar todas las noches! Puede decir adiós a las bombas de gasolina y dejar de ir a la gasolinera durante la semana, lo que le ahorrará tiempo y dinero. Y a diferencia de la gasolina, que necesita una estación específica, el 'combustible eléctrico' está disponible en muchos más lugares. Su casa, hoteles, cines, centros comerciales, tiendas de comestibles, centros vacacionales, ¡y mucho más! Incluso puede conectarse a una toma de corriente normal.{br} Incluso cuando no está en casa, también puede cargar en cientos de puertos de carga disponibles para el público, tanto en Austin como fuera de la ciudad. Encontrar estaciones de carga es fácil.{br} Sólo tiene que descargar la aplicación {link}, elegir su vehículo eléctrico y aparecerán automáticamente todas las estaciones de carga para su automóvil.",
    "faq10" : "¿Cuáles son las diferentes formas de cargar su VE?",
    "faq10A" : "Al igual que los automóviles de gasolina que pueden utilizar 'Regular' o 'Premium', los VE pueden utilizar diferentes tipos de enchufes. Con la aplicación Chargeway, los enchufes de su vehículo eléctrico están codificados por colores según las estaciones que puede utilizar para su vehículo seleccionado. Hay tres colores de enchufe:",
    "faqContent1-1" : "Para Chevrolet, BMW, VW y otras marcas estadounidenses/europeas",
    "faqContent1-2" : "Tanto para carga estándar como rápida",
    "faqContent1-3" : "A menudo se denomina Combo J1772 y CCS",
    "faqContent2-1" : "Para Nissan, Mitsubishi, Kia y algunas marcas del mercado asiático",
    "faqContent2-2" : "SÓLO carga rápida",
    "faqContent2-3" : "A menudo denominada 'CHAdeMO'",
    "faqContent3-1" : "SÓLO para vehículos Tesla",
    "faqContent3-2" : "anto carga estándar como rápida",
    "faqContent3-3" : "A menudo se denominan 'cargadores de destino' y 'supercargadores'",
    "faq11" : "¿Cuánto tiempo dura la carga?",
    "faq11A" : "Hay siete niveles de potencia diferentes para la carga, incluyendo la carga estándar y la carga rápida. Algunos VE sólo pueden utilizar la carga estándar, mientras que otros pueden utilizar tanto la carga estándar como la rápida.",
    "stdCharging" : "Carga estándar:",
    "faqContent4-1" : "Nivel 1",
    "faqContent4-2" : "Este nivel utiliza el cable de carga suministrado con cada VE",
    "faqContent4-3" : "Proporciona de 3 a 5 millas por hora de carga",
    "faqContent4-4" : "Nivel 2",
    "faqContent4-5" : "Este nivel ofrece una carga más rápida en el hogar y es apropiado para la carga en el lugar de trabajo",
    "faqContent4-6" : "Proporciona de 20 a 60 millas por hora de carga",
    "faqFastCharge" : "Carga rápida:",
    "faqContent5AA" : "Los vehículos eléctricos con capacidad de carga rápida pueden utilizar los niveles 3 a 7. Algunos VE pueden cargar más rápidamente a niveles más altos que otros VE. Descargue la aplicación {link} para ver qué estaciones puede utilizar su vehículo y a qué velocidad puede cargar. Las velocidades típicas de carga rápida son:{br} Nivel 3: Proporciona 80-100 millas por hora de carga{br}  Nivel 4: Proporciona entre 101 y 175 millas por hora de carga{br} Nivel 5: Proporciona 200-350 millas por hora de carga{br} Nivel 6: Proporciona 400-700 millas por hora de carga{br} Nivel 7: Proporciona entre 1,200 y 1,400 millas por hora de carga{br} La carga rápida está disponible por $0.21 por minuto, convenientemente situada cerca de las principales rutas de transporte para los momentos en que necesite cargar y salir. Si desea utilizar un puerto de carga público y tiene previsto alejarse de su vehículo durante más de 30 minutos, utilice un cargador de nivel 2 en lugar de un cargador rápido.",
    "faqContent6" : "¿Necesito comprar una estación de carga doméstica? Si es así, ¿cuál?",
    "faqContent7" : "Los nuevos vehículos enchufables vienen con conectores Green 1 que se conectan a las tomas de corriente domésticas estándar de 120 voltios. Por tanto, no necesita comprar una estación de carga doméstica siempre que tenga acceso a una toma de corriente estándar. Sin embargo, para una carga más rápida, muchos conductores compran una estación de carga Green 2 para conectarla a una toma de corriente de 240 voltios. Todas las estaciones de carga Green 2 vienen con el conector Green estándar que permite conectar cualquier vehículo enchufable. {br} Los vehículos Tesla vienen con un conector rojo 1. Puede adquirir un conector de pared de alta potencia (Rojo 2) a través de Tesla. Los propietarios de Tesla también pueden utilizar el adaptador verde de carga estándar para conectarse a cualquier estación verde de nivel estándar.",
    "faqContent8" : "¿No aumentaría eso la factura de la luz?",
    "faqContent9C" : "Sí, pero no por mucho. La electricidad cuesta mucho menos que la gasolina como combustible. Compare y calcule su ahorro {link}.",
    "faqHere" : "aquí",
    "faqContent10" : "¿Tarda mucho en cargarse?",
    "faqContent11B" : "Las estaciones verdes de nivel 1 y 2 están disponibles en los lugares de trabajo, los comercios minoristas y otros lugares. Además, las estaciones de carga rápida están disponibles para cargar en movimiento dentro de Austin y más allá. Para ver dónde puede viajar con su VE, descargue la {link} y utilice el Planeador de Viajes.",
    "faqContent12" : "¿Cómo puedo cargar mi automóvil eléctrico en movimiento?",
    "faqContent13" : "Los niveles 1 y 2 están disponibles en los centros de trabajo, los comercios minoristas y otros lugares. Además, está disponible una opción más rápida llamada DC Fast Charge para cargar en movimiento.",
    "faqContent14" : "Suscripción de carga EVerywhere℠ de Austin Energy Plug-In",
    "faqContent15" : "¿Qué es la suscripción a Plug-In EVerywhere?",
    "faqContent16" : "El plan de suscripción a la red Plug-In EVerywhere de Austin Energy ofrece carga ilimitada por $4.17 dólares al mes en más de 1,000 puertos de carga Plug-In EVerywhere de nivel 2 en toda el área de servicio de Austin Energy. (Tenga en cuenta que las estaciones de recarga para multifamiliares, flotas y lugares de trabajo pueden tener acceso restringido) Todos nuestros puertos de carga de Plug-In EVerywhere están alimentados por energía eólica 100% de Texas a través de nuestro programa GreenChoice de Austin Energy. La suscripción se factura en la factura de servicios del cliente $25 cada 6 meses o se paga con tarjeta de crédito.",
    "faqContent17" : "¿Quién puede suscribirse?",
    "faqContent18" : "Cualquier conductor de VE que viva, trabaje o visite Austin puede suscribirse. No tiene que ser un cliente de electricidad de Austin Energy.",
    "faqContent19" : "¿Puedo cargar en una estación Plug-In EVerywhere si no soy socio?",
    "faqContent20" : "Sí, también puede acceder a las estaciones de nivel 2 sin necesidad de ser miembro de Plug-In EVerywhere. La inscripción para la tarifa de los no miembros es de $2/hr.{br} La carga rápida está disponible por $0.21 por minuto, convenientemente situada cerca de las principales rutas de transporte para los momentos en que necesite cargar y salir. Si desea utilizar un puerto de carga público y tiene previsto alejarse de su vehículo durante más de 30 minutos, utilice un cargador de nivel 2 en lugar de un cargador rápido.",
    "faqContent21" : "Un vehículo no eléctrico está estacionado en un punto de la estación de carga. ¿Qué puedo hacer?",
    "faqContent22" : "Por favor, llame al 311 de Austin para informar de que un vehículo no eléctrico está aparcado en un espacio de la estación de carga. (El 311 conecta con la aplicación de las normas de estacionamiento)",
    "faqContent23" : "Vehículos",
    "faqContent24" : "¿Qué es un vehículo eléctrico?",
    "faqContent25" : "¿Por qué debería conducir un VE?",
    "faqContent26" : "¿Qué debo tener en cuenta antes de comprar un vehículo eléctrico?",
    "faqContent27" : "¿Qué tan rápidos son los VE?",
    "faqContent28" : "¿Qué mantenimiento requieren los vehículos eléctricos?",
    "faqContent29" : "¿Son las baterías de los vehículos eléctricos mejores que el uso de la gasolina en los automóviles?",
    "faqContent30" : "¿Son los vehículos eléctricos realmente mejores para el medio ambiente?",
    "faqContent31" : "¿Por qué debería conducir un VE?",
    "faqContent32" : "¿Qué es un automóvil ICE?",
    "faqContent33" : "Cargando",
    "faqContent34" : "¿Dónde puedo cargar mi VE?",
    "faqContent35" : "¿Cuáles son las diferentes formas de cargar su VE?",
    "faqContent36" : "¿Cuánto tiempo dura la carga?",
    "faqContent37" : "¿Necesito comprar una estación de carga doméstica? Si es así, ¿cuál?",
    "faqContent38" : "¿No aumentaría eso la factura de la luz?",
    "faqContent39" : "¿Tarda mucho en cargarse?",
    "faqContent40" : "¿Cómo puedo cargar mi automóvil eléctrico en movimiento?",
    "faqContent41" : "¿Qué es la suscripción a Plug-In EVerywhere?",
    "faqContent42" : "¿Quién puede suscribirse?",
    "faqContent43" : "¿Puedo cargar en una estación Plug-In Everywhere si no soy socio?",
    "faqContent44" : "Un vehículo no eléctrico está estacionado en un punto de la estación de carga. ¿Qué puedo hacer?",
    "faqContent45" : "Austin Energy Plug-In EVerywhere&#8480; Charging Subscription",

    "vehicle.miles" : "Millas",
    "vehicle.trees" : "Árboles",
    "vehicle.view" : "Ver",
    "vehicle.more" : "más",
    "vehicle.fewer" : "menos",
    "vehicle.greater" : "más",

    "vehicle.plugInHybrid": "Híbrido Eléctrico Enchufable",
    "vehicle.allElectric": "Eléctricos",
    "vehicle.gas": "Gasolina",
    "vehicle.afterIncentives" : "Después de incentivos",
    "vehicle.msrp" : "MSRP",
    "vehicle.estimatedIncentives" : "Insentivos estimados",
    "vehicle.type" : "Tipo",
    "vehicle.fuelType" : "Tipo de combustible",
    "vehicle.batterySize" : "Tamaño de la batería",
    "vehicle.electricRange" : "Rango eléctrico",
    "vehicle.timeToChargeLvlTwo" : "Tiempo de carga - Nivel 2",
    "vehicle.milesPerThirtyFastCharge" : "Millas por 30 minutos de carga rápida",
    "vehicle.coTwoEmissions" : "Reducción de emisiones de CO2",
    "vehicle.phev" : "Electricidad y gasolina ",
    "vehicle.bev" : "Electricidad",
    "vehicle.age.one" : "0-1 Años",
    "vehicle.age.two" : "2-3 Años",
    "vehicle.age.four" : "4+ Años",

    "vehicle.type.sedan" : "Sedan",
    "vehicle.type.hatchback" : "Cinco puertas",
    "vehicle.type.coupe" : "Cupé",
    "vehicle.type.crossover" : "Híbrido",
    "vehicle.type.minivan" : "Minivan",
    "vehicle.type.suv" : "SUV",
    "vehicle.type.wagon" : "Camioneta",
    "vehicle.type.truck" : "Camión",

    "footerInventory" : "Inventario - Guía del comprador de VE de Austin Energy",
    "findDealers" : "Buscar concesionarios",
    "evFAQ" : "Preguntas Frecuentes Sobre Los Ve",
    
    "footer.allRights" : "Todos los derechos reservados.",
    "footer.poweredBy" : "Patrocinado por Zappyride",

    "homepage.welcome": "Colton Recharged",
    "homepage.welcomeSub" : "Su guía para autos eléctricos, diseñada por Colton Electric Utility. Obtenga un estimado y compare costos, opciones de ahorro, incentivos y mucho más para VE.",

    "homepage.linkCard.browseElectricVehicles" : "VEHICULOS {lineBreak} ELÉCTRICOS",
    "homepage.linkCard.researchElectricVehicles" : "INVESTIGAR {lineBreak} ELÉCTRICOS",
    "homepage.linkCard.discoverIncentives" : "DESCUBRA {lineBreak} INCENTIVOS",
    "homepage.linkCard.locateChargingStations" : "ENCUENTRE ESTACIONES {lineBreak} DE CARGA",
    "homepage.linkCard.homeChargers" : "CARGADORES PARA {lineBreak} EL HOGAR",
    "homepage.linkCard.findDealers" : "ENCONTRAR {lineBreak} DISTRIBUIDORES",

    "homepage.vehicleCarousel.title" : "Existen {vehicleCount} vehículos eléctricos disponibles. Descubra el suyo.",
    "homepage.vehicleCarousel.subTitle" : "Elija un VE y compare el costo con un vehículo de gasolina similar. Los VE’s le permiten ahorrar dinero porque {lineBreak} son más económicos y baratos de poseer y mantener a comparación de un competidor de 100% gasolina.",
    "homepage.vehicleCarousel.findEVButton" : "Encuentre su vehículo eléctrico",

    "homepage.fuelRangeChart.title" : "Hasta dónde puede conducir el {car} con un {budget} presupuesto de combustible?",

    "graph.assumptions" : "Supuestos",
    "graph.yourEV" : "VE seleccionado",
    "graph.similarGasVehicle" : "Vehículo comparable a gasolina ",
    "graph.graphValues": "VALORES GRAFICADOS",
    "graph.show" : "MOSTRAR ",
    "graph.hide" : "OCULTAR ",
    "graph.moreExpensive" : "mas caro",
    "graph.cheaper" :"mas barato",
    "graph.toOwnOver" : "de tener por ",
    "graph.years" : " Años",
    "graph.year" : " Año",

    "graph.costOfOwnership.title" : "Costo de Propiedad",
    "graph.costOfOwnership.subTitle" : "El {carName} es {style} {costDeltaText} {costDeltaTextEnding}",
    "graph.costOfOwnership.subTitleThreeCars" : "El {carName} es el más barato de tener por {yearsOfOwnership} años",
    "graph.costOfOwnership.descriptionRow.vehicle" : "Vehículo",
    "graph.costOfOwnership.descriptionRow.maintenance" : "Mantenimiento",
    "graph.costOfOwnership.descriptionRow.insurance" : "Seguro",
    "graph.costOfOwnership.descriptionRow.electricity" : "Electricidad",
    "graph.costOfOwnership.descriptionRow.gasoline" : "Gasolina",
    "graph.costOfOwnership.totalRow" : "Total",
    "graph.costOfOwnership.description" : "Descripción",

    "graph.costOfOwnership.chart.vehicle" : "Incentivos para vehículo, Reventa.",
    "graph.costOfOwnership.chart.electricity" : "Electricidad",
    "graph.costOfOwnership.chart.gasoline" : "Gasolina",
    "graph.costOfOwnership.chart.maintenance" : "Mantenimiento",
    "graph.costOfOwnership.chart.insurance" : "Seguro",

    "graph.title.costAnalysis" : "Análisis de Costos",
    "graph.title.speedOfCharge" : "Velocidad de Carga",
    "graph.title.electricRange" : "Rango Eléctrico",
    "graph.monthlyCostToFill.title" : "Costo de Llenar el Tanque Mensualmente",
    
    "graph.speedOfCharge.levelTwoChargingSpeed" : "Velocidad de carga Nivel 2",
    "graph.speedOfCharge.fastChargingSpeed" : "Velocidad de carga rápida",
    "graph.speedOfCharge.mphLevelTwoCharger" : "millas por hora al usar un cargador nivel 2 ",
    "graph.speedOfCharge.thirtyFastCharge" : "millas por cada 30 minutos de carga rápida",
    "graph.speedOfCharge.subTitle" : "El {car} tendrá {number} {costDeltaText} {subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerThreeCars" : "será el que gane más rango de carga tras una hora de uso de un cargador nivel 2",
    "graph.speedOfCharge.thirtyFastChargeThreeCars" : "será el que gane más rango de carga después de 30 minutos de carga rápida",
    "graph.speedOfCharge.subTitleThreeCars" : "El {car} {subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerTitle" : "Porcentaje de carga por hora en Nivel 2",
    "graph.speedOfCharge.fastChargerTitle" : "Cantidad de millas por 30 minutos de carga rápida.",
    "graph.electricRange.subTitleThreeCars" : "El {car} tiene el rango eléctrico más amplio de todos",
    "graph.electricRange.subTitle" : "El rango eléctrico del {car} tiene {number} millas { costDeltaText} ",
    "graph.electricRange.gasolineRange" : "Rango de gasolina",
    "graph.electricRange.assumption" : "Rango basado en estimaciones de la EPA",
    "graph.monthlyCostToFill.subTitle" : "El {carName} es {style} {costDeltaText} de tanquear mensualmente",
    "graph.monthlyCostToFill.subTitleThreeCars" : "El {carName} el más barato de tanquear mensualmente",

    "homepage.incentives.title" : "Descubra incentivos y créditos fiscales para VE ",
    "homepage.incentives.subTitle" : "Descubra que tanto puede ahorrar con un VE, ya sea por compra o alquiler. Los incentivos son personalizados para {company} clientas.",
    "homepage.incentives.exporeIncentives" : "DESCUBRA INCENTIVOS",


    "compareVehicles.subTitle" : "Ver todos los vehículos >",
    "compareVehicles.selectFuel" : "Seleccione el Tipo de Combustible ",
    "compareVehicles.selectMake" : "Seleccione la marca",
    "compareVehicles.selectModel" : "Seleccione el modelo",
    "compareVehicles.pickTwo" : "Elíja al menos 2 vehículos para hacer la comparación",
    "compareVehicles.disclaimer" : "No todas las marcas / modelos están disponibles.",

    "evs.welcomeSub" : "Compara autos eléctricos por su rango, precio, o por características que más se acomoden a tu gusto. {lineBreak} Haz click sobre el VE para obtener detalles como el costo total comparado con vehículos de gasolina.",
    "evs.buttonMatchScoreAndFilters" : "Puntaje personalizado y Filtros",
    "evs.matchScoreAndFilters" : "Asignar puntaje personalizado y Filtros",
    "evs.matchScoreOptions" : "Asignar Puntaje Personalizado",
    "evs.roundTripCommute" : "Viaje de ida y vuelta",
    "evs.budgetAfterIncentives" : "Presupuesto después de incentivos",
    "evs.minSeats" : "Cantidad mínima de asientos",
    "evs.seats" : "Asientos",
    "evs.homeChargingAvailability" : "Disponibilidad de carga en el hogar",
    "evs.homeChargingAvailabilityTooltip" : "Más autos eléctricos serán mejores para ti si tienes la posibilidad de cargarlos rapidamente en casa.",
    "evs.noCharging" : "Sin carga",
    "evs.levelOne" : "Nivel 1",
    "evs.levelTwo" : "Nivel 2",
    "evs.helpMeChoose" : "Ayúdame a elegir",
    "evs.chargingAvailability" : "Disponibilidad de carga",
    "evs.disclaimer" : "Es posible que los vehículos mostrados en esta página no reflejen la disponibilidad real. {company} no respalda ni recomienda ningún modelo o fabricante de vehiculos específico. ",
    
    "vehiclesAvailabilityButton" : "Solo vehículos locales",

    "chargingWizard.carOvernight" : "¿En dónde parquear tu Auto en la noche?",
    "chargingWizard.parkingGarage" : "Garaje privado o estacionamiento",
    "chargingWizard.onTheStreet" : "En la calle",
    "chargingWizard.publicParking" : "Estacionamiento público",
    "chargingWizard.electricianInstall" : "¿Puede un electricista instalarme una estación de carga?",
    "chargingWizard.overnightParking" : "¿Podrías estacionar tu auto en un lugar que cuenta con estación de carga?",
    "chargingWizard.powerOutlet" : "¿Existe algún tomacorriente cerca al lugar en donde estacionas tu auto?",
    "chargingWizard.chargingAvailability" : " Disponibilidad de carga",
    "chargingWizard.noChargingAvailability" : "No disponibilidad de carga",
    "chargingWizard.youWouldHave" : "Tendrás {resultText} ",
    "chargingWizard.useThisValue" : "Usar este valor",

    "evfilter" : "Filtro",
    "evfilter.fuel" : "Combustible",
    "evfilter.fuelTooltip" : "Los vehículos eléctricos solo usan electricidad. Los vehículos híbridos pueden funcionar con electricidad y gasolina.",
    "evfilter.type" : "Tipo",

    "yes" : "Si",
    "no" : "No",
    "availability" : "Disponibilidad",
    "availability-Tooltip" : "Estos vehículos se han visto en el inventario de concesionarios locales del área de Austin en el pasado reciente.",

    "vehiclesFuelTip" : "Los vehículos eléctricos solo usan electricidad. Los vehículos híbridos pueden funcionar con electricidad y gasolina.",

    "evCard.electricRange" : "Rango eléctrico",
    "evCard.totalRange" : "Rango total",
    "evCard.matchScore" : "Puntaje personalizado",
    "evCard.seeDetails" : "DETALLES",
    "evCard.seeElectricVehicle" : "Ver Vehículo Eléctrico",

    "graph.fuelRange.chart.description": "Description",
    "graph.fuelRange.chart.rangeOn": "Rango encendido",
    "graph.fuelRange.chart.fuelBudget": "presupuesto de combustible",
    "graph.fuelRange.chart.network": "en la Plug-In EVerywhere network",
    "graph.fuelRange.chart.unlimited": "Ilimitado",
    "graph.fuelRange.chart.chargedHome": "cargado en casa",
    "graph.fuelRange.chart.similarGas": "un vehículo de gas similar",
    "graph.fuelRange.chart.miles": "millas",
    "graph.fuelRange.chart.howFar" : "Hasta dónde puede conducir el {car} en un {budget} presupuesto de combustible?",

    "evSort.title" : "Ordenar por",
    "evSort.matchScore" : "Puntaje personalizado",
    "evSort.electricRange" : "Rango eléctrico",
    "evSort.priceLtH" : "Precio: Menor a mayor",
    "evSort.priceHtL" : "Precio: Mayor a menor",
    "evSort.alphabetical" : "Orden alfabético",

    "inventoryFilter" : "Filtro de vehículos",
    "inventoryAll" : "Todos",
    "inventoryNew" : "Neuvo",
    "inventoryUsed" : "Usó",

    "pricePanels.cash" : "Efectivo",
    "pricePanels.loan" : "Crédito",
    "pricePanels.lease" : "Arrendamiento",
    "pricePanels.loanPayment" : "Pago del préstamo",
    "pricePanels.downPayment" : "Cuota inicial",
    "pricePanels.leasePayment" : "Pago de arriendo",
    "pricePanels.perMonth" : " / Mes",
    "pricePanels.downPaymentDescription" : "10% de MSRP más impuestos",
    "pricePanels.firstLeasePayment" : "Primera cuota de la renta",
    "pricePanels.incentivesForLease" : "Incentivos para rentar",
    "pricePanels.seeAllIncentives" : "Ver todos los incentivos",
    "pricePanels.seeAllCosts" : "Ver todos los costos",

    "ev.jumbotron.viewAllCars" : "Ver todos los autos",
    "ev.jumbotron.disclaimer" : "Consulte las páginas {evFacts} y {faq} para obtener explicaciones detalladas sobre su EV.",
    "ev.carDetails.fastChargingTooltip" : "Velocidad de carga rápida con DC:",
    "ev.carDetails.levelTwoChargerSpeedTooltip" : "Velocidad de carga Nivel 2:",
    "ev.carDetails.batterySizeTooltip" : "Un Kilovatio por hora (kWh) provee suficiente energía para conducir de 1 a 4 millas dependiendo del vehículo. Una batería común para computadora puede contener menos de 1 kWh",
    "ev.carDetails.treesPlanted" : "Árboles plantados",
    "ev.carDetails.gasolineSaved" : "Gasolina ahorrada",
    "ev.carDetails.compareText" : "Seleccionamos el {gasVehicle} por su proximidad con el {vehicle} con base en la marca, tamaño y precio.",
    "ev.cardetails.calc.dcRate" : "Cargador Rápido (DCFC)",
    "ev.cardetails.calc.EPA" : "Calificación de eficiencia EPA",
    "ev.cardetails.calc.chargingRate" : "Velocidad de carga",
    "ev.cardetails.calc.vehicleBatteryCapacity" : "Capacidad de la batería",
    "ev.cardetails.calc.maxACIntake" : "Capacidad maxima de AC",
    "ev.cardetails.calc.lvlTwoChargingRate" : "Velocidad de carga Nivel 2",
    "ev.cardetails.calc.minOflvl2AC" : "Nivel mínimo de carga y capacidad máxima de absorcion del AC",
    "ev.cardetails.calc.lvl2FullCharge" : "Tiempo de carga total en Nivel 2",
    "ev.cardetails.calc.milesDrivenPerYr" : "Millas conducidas al año",
    "ev.cardetails.calc.userInput" : "Introducción de datos por el usuario",
    "ev.cardetails.calc.milesPerGallon" : "Millas por Galón (mpg)",
    "ev.cardetails.calc.mpg" : " millas/gal",
    "ev.cardetails.calc.portionElectric" : "Porción eléctrica usada",
    "ev.cardetails.calc.portionElectricSource" : "Introducción de datos por el usuario. Para un vehículo híbrido, estima la porción de millas conducidas usando batería eléctrica en vez de gasolina.",
    "ev.cardetails.calc.gallonsUsed" : "Galones Usados",
    "ev.cardetails.calc.gallonsUsedFormula" : "Millas manejadas * (1 - Porción eléctrica usada) / MPG",
    "ev.cardetails.calc.gallonsSaved" : "Galones Ahorrados",
    "ev.cardetails.calc.gallonsSavedFormula" : "Galones usados por un vehiculo a gasolina comparable - Galones usados por VE",
    "ev.cardetails.calc.electricEmissions" : "Emisiones por consumo eléctrico en Lbs",
    "ev.cardetails.calc.electricEmissionsLbsMwh" : "Emisiones de CO2 por consumo eléctrico en lbs/MWh",
    "ev.cardetails.calc.EIA" : "Administración de información energética de Estados Unidos",
    "ev.cardetails.calc.gasolineEmissionsYr" : "Emisiones por consumo de gasolina en lbs de CO2/yr",
    "ev.cardetails.calc.gasolineEmissions" : "Emisiones de CO2 por consumo de gasolina en lbs/gal",
    "ev.cardetails.calc.emissionsReudctions" : "Reducción de emisiones",
    "ev.cardetails.calc.lbsCo2PerYr" : " lbs de CO2 al año",
    "ev.cardetails.calc.gasolineEmissionsLbs" : "Emisiones por consumo de gasolina en lbs",
    "ev.cardetails.calc.arborDayFoundation" : "Fundación Arbor Day",
    "ev.cardetails.calc.emissionsPerTree" : "Emisiones capturadas por árbol por año",
    "ev.cardetails.calc.electricityEmissions" : "Emisiones de CO2 por consumo eléctrico en lbs/MWh",
    "ev.usedCarTitle" : "{car} Usado",
    "ev.cardetails.electricRange" : "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
    "ev.carDetails.usedBatterySizeTooltip" : "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",

    "ev.purchaseMethod" : "Forma de pago",
    "ev.purchaseMethodToolTip" : "En modo renta, usted tiene que devolver el auto al final del periodo de arrendamiento. En el caso de financiación con un crédito, usted se queda con el auto al terminar de pagar el crédito.",
    "ev.cash" : "Efectivo",
    "ev.loan" : "Crédito",
    "ev.lease" : "Renta",
    "ev.milesDrivenAnnually" : "Millas conducidas por año",
    "ev.electricPortion" : "Parte eléctrica de un PHEV",
    "ev.electricPortionTooltip" : "A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.",
    "ev.yearsOwnership" : "Compra/renta al año",
    "ev.interestRate" : "Tasa de interés",
    "ev.electricityRateCalc" : "Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}",

    "assumption.salesTax" : "Impuesto a las ventas",
    "assumption.gasolinePrice" : "Precio de gasolina",
    "assumption.downPayment" : "Cuota inicial",
    "assumption.electricityRate" : "Rango eléctrico",
    "assumption.downPaymentValue" : "10% de MSRP más impuestos",

    "ev.compareGasVehicle" : "Compare el {car} con un vehículo similar a gasolina, el {gasCar}.",
    "ev.compareOtherVehicles" : "COMPRE OTHER VEHICLES",
    "ev.incentives.title" : "Hasta {savings} en créditos fiscales y reembolsos están disponibles {lineBreak} para el {car}.",
    "ev.reviews" : "Reseñas en la web",

    "chargingMap.updateZipcode" : "Actualizar Código Postal",
    "chargingMap.errorZipcode" : "Error: {workingZipcode} no es un código postal válido",
    "chargingMap.showFullListOfStations" : "lista completa de estaciones",
    "chargingMap.public" : "Estaciones Públicas",
    "chargingMap.businessGovernment" : "Instaladas por empresas o el gobierno",
    "chargingMap.highPowerStations" : "Estaciones de Alta Potencia",
    "chargingMap.DCChargers" : "Carga rápida CC o súper-cargadores",
    "chargingMap.otherSttations" : "Otros Tipos de Estaciones",
    "chargingMap.privateStations" : "Estaciones rivadas",
    "chargingMap.searchDealers" : "Buscar Distribuidores Calificados",

    "dealers.brands" : "Marcas",
    "dealers.allBrands" : "Todos las Marcas",
    "dealers.allDealers" : "Todos los Distribuidores",
    "dealers.contact" : "CONTACTO",
    "dealers.website" : "SITIO WEB",
    "dealers.findDealers" : "Encontrar Distribuidores",

    "incentives.title" : "Incentivos para Vehículos Eléctricos",
    "incentives.subTitle" : "Usted puede ser elegible para un rango de incentivos, incluyendo reembolsos, créditos fiscales y otros beneficios. Los incentivos son personalizados según su lugar de residencia.",
    "incentives.single" : "Soltero",
    "incentives.married" : "Casado",
    "incentives.headOfHousehold" : "Cabeza de hogar",
    "incentives.vehicleType" : "Tipo de vehículo",
    "incentives.vehicleTypeTooltip" : "Ciertos incentivos dependen de qué carro compres.",
    "incentives.tradeIn" : "Intercambio",
    "incentives.income" : "Estado de ingresos e impuestos",
    "incentives.incomeTooltip" : "La elegiblidad para incentivos y la cantidad que puedes recibir depende normalmente de tu nivel de ingresos. Clientes con niveles de ingresos menores son usualmente elegibles para reembolsos mayores.",
    "incentives.clunkerNone" : "Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.",
    "incentives.clunkertoolTip" : "Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.",
    "incentives.clunkertoolTipContd" : " Están disponibles en tu región. Los requisitos para elegibilidad varían por incentivo, por favor revise los detalles del programa.",
    "incentives.householdSize" : "Tamaño del Hogar",
    "incentives.householdIncome" : "Ingresos del Hogar",
    "incentives.planClunker" : "¿Estás planeando intercambiar tu carro viejo?",
    "incentives.location" : "Ubicación",
    "incentives.locationTooltip" : "Muchos incentivos dependen del lugar en el que vives. Por favor ingresa tu código zip para ayudarte a determinar cuáles incentivos están disponibles en tu lugar de residencia.",
    "incentives.taxFilingStatus" : "Estado Civil para Efectos de la Declaración",
    "incentives.whichVehicle" : "¿Qué vehículo planeas comprar?",
    "incentives.incentiveEligibility" : "Actualizar Elegibilidad para Incentivos",

    "homeChargers.subText" : "Seleccione el vehículo que le interesa para ver los detalles de tiempo de carga total de la bateria.",
    "homeChargers.wallMounted" : "Montaje en pared",
    "homeChargers.portable" : "Portátil",
    "homeChargers.price" : "Precio",
    "homeChargers.cordLength" : "Longitud del cable",
    "homeChargers.wifi" : "Conectividad Wifi",
    "homeChargers.socket" : "Enchufe",
    "homeChargers.socketTooltip" : "Cada modelo de cargador tiene un patrón de enchufe diferente. Estos tipos de patrón corresponden a la forma en la que las puntas encajan en la toma de corriente de la pared. Si no está seguro de cuál es el mejor para su hogar/garaje, consulte con un electricista calificado..",
    "homeChargers.cordLengthLtH" : "Longitud del cable: Menor a mayor",
    "homeChargers.cordLengthHtL" : "Longitud del cable: Mayor a menor",
    "homeChargersCard.buy" : "Detalles y Compra",
    "homeChargersCard.beforeIncentives" : "Antes de incentivos",
    "homeChargersCard.socket" : "Enchufe {socket}",
    "homeChargers.cable" : "Cable de {length} ft.",
    "homeChargers.hardwired" : "Cableado Enchufe",
    "homeChargers.toFullCharge" : "para carga completa",

    "pages" : "Páginas",

    "footerNavigationOtherResources" : "Otros recursos energéticos en Austin",
    "footerNavigationEVPrograms" : "Programas de VE de Austin Energy",
    "footerNavigationSolar" : "Solar",
    "footerNavigationGreenChoice" : "GreenChoice®",
    "footerNavigationGreenBuilding" : "Edificio ecológico de Austin Energy",
    "footerNavigationRebatesIncentives" : "Rebajas e incentivos",
    "footerNavigationContact" : "Contacto",

    "electricians.residential" : "Residencial",
    "electricians.commercial" : "Comercial",
    "electricians.industrial" : "Industrial",
    "electricians.type" : "Tipo de Electricista",
    "electricians.searchElectricians" : "Buscar Electricistas Calificados",

    "activate.membership" : "Activa tu",
}

