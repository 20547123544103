export default {
  home: "Home",
  shopLocal: "Shop Local EVs",
  shopDealers: "Shop Dealers",
  viewVehicleDetails: "View Vehicle Details",
  browseInventory: "Browse Nearby Inventory",
  dealerDirectory: "Dealer Directory",
  evFacts: "EV Facts",
  faq: "FAQ",
  electricVehicles: "Electric Vehicles",
  usedElectricVehicles: "Used Electric Vehicles",
  compareVehicles: "Compare Vehicles",
  incentives: "Incentives",
  chargingStations: "Charging Stations",
  homeChargers: "Home Chargers",
  dealers: "Dealers",
  newVehicles: "New Vehicles",
  electricians: "Electricians",

  electricity: "Electricity",
  gasoline: "Gasoline",
  total: "Total",
  description: "Description",
  detailedCalculations: "Detailed Calculations",
  charging: "Charging",
  emissions: "Emissions",
  source: "Source",
  vehicles: "Vehicles",
  make: "Make",
  model: "Model",
  all: "All",
  age: "Age",
  zipcode: "Zipcode",

  dealersHeading: "Dealers",
  dealersSubheading:
    "The dealers below work closely with Austin Energy to provide a great EV shopping experience. Contact and visit these dealers with the confidence that they'll help you pick the right vehicle for you.",

  personalizeIncentives: "Personalize Incentives",

  "home.card.electricVehicles": "Shop Local Electric Vehicles",

  factsSubheading:
    "Electric vehicles are easy! Here are five fun facts about EVs.",
  factsUseHeading: "EASY TO USE",
  factsUseContent:
    "Plug into a regular outlet at home or use a charging station for a faster charge.",
  factsMaintain: "EASY TO MAINTAIN",
  factsMaintainContent:
    "Enjoy more time and money in your pocket since EVs require less maintenance and have fewer moving parts.",
  factsCharge: "EASY TO CHARGE",
  factsChargeContent:
    "Charge for only {amount} a month at any of the 800+ local Austin Energy charging ports powered by 100% renewable Texas wind energy.",
  factsDriveEasy: "EASY TO DRIVE",
  factsDrivePerformance:
    "Get the high performance you want in a car, when you choose to lease, buy or get a used EV.",
  factsDriveContent: "EASY TO FIND!",
  factsBrowse: "Browse the new {website} to find your vehicle now! {link}",
  factsBrowseLink: "See what EV drivers are saying.",

  faqHeading: "Austin Energy EV FAQ",
  faqVehicles: "Vehicles",
  faq1: "What is an Electric Vehicle?",
  faq1AA:
    "An electric vehicle is any vehicle that can drive on electricity. If you can plug it in to fuel it, it is an electric vehicle. An all-electric vehicle (sometimes called a battery electric vehicle or a “BEV”) drives solely on electricity and does not use gasoline. {link} {br} Two kinds of electric vehicles are available: ",
  "faq1A-link": "What types of electric vehicles are available?",
  "faq1A-1":
    "Battery Electric Vehicle (BEV) - relies exclusively on a battery to power the car. Say goodbye to the gas pump!",
  "faq1A-2":
    "Plug-In Hybrid Electric Vehicles (PHEV) - uses both electricity and gasoline. When driving, they use battery power and switch to gasoline when the battery is empty.",
  faq2: "Why should I drive an EV?",
  faq2A:
    "Easy to use, easy to maintain and easy to charge, electric vehicles are fun to drive and can save you money while helping the environment. EVs are cheaper, faster, and cleaner to operate plus they have much lower maintenance costs. If you are like most people commuting about 30 miles a day, then an EV can mean more money in your bank account and more time to enjoy your life.",
  faq3AA:
    "Similar to selecting a gasoline-powered car, choosing the electric vehicle that’s best for you depends on a number of different factors including your driving habits and personal preference. Here are some EV-specific factors to consider:",
  faqTotalRange: "Total Range:",
  faqSavings: "Savings:",
  "faq3A-1":
    "{totalRange} How far will you travel? The total range of current electric vehicles vary greatly—anywhere from 40-350 miles.",
  "faq3A-2":
    "{charging} Where will you charge? Where you drive and how you'll charge your vehicle can help you decide which electric vehicle will meet your needs.",
  "faq3A-3":
    "{savings} How often does your gas-powered car require maintenance? How often are you filling your gas tank? EV drivers save on electric fuel and car maintenance, which also saves time and helps the environment.",
  "faq3A-4":
    "If your daily commute is less than 40 miles, virtually every electric vehicle will be able to handle your daily driving without the need for fuel. If you want the ability to drive much farther, several all-electric vehicles can travel 100 to 300+ miles on a charge. For the rare road trip, the drive can be done stopping at charging stations on the way to your destination.",
  faq4: "How fast are EVs?",
  faq4A:
    "Fast! When you hit the accelerator EVs GO! EVs provide instant power (torque), providing faster acceleration than any gas-powered car you have had before, making EVs very fun to drive. With an electric motor, the driving experience is also quieter - and often more enjoyable - than a traditional gasoline-fueled car.",
  faq5: "What maintenance do electric vehicles require?",
  faq5AA:
    "With an EV, there are no oil changes, no spark plugs, no timing belts. No more expensive fixes over time.{br} Since there are significantly fewer moving parts in an EV compared to a traditional vehicle, less ongoing preventative maintenance is needed (just tire rotations). Since there's no exhaust, there's also no muffler or catalytic converter. That means more money in your pocket.{br} Brakes last longer on EVs too. When you take your foot off of the accelerator the electric motor slows the car down. It even uses the extra energy and stores it back in the battery giving your EV a little more range.{br} All automakers also offer at least 8-year warranties on the batteries, if not longer.",
  faq6: "Are EV batteries better than the use of gasoline in cars?",
  faq6A:
    "In many ways, yes. Unlike gasoline, EV batteries do not emit harmful carbon emissions into the atmosphere to pollute air and water. Also, once the batteries cannot be used for cars anymore, they can be reused for energy storage. Some car companies are already using blocks of old EV batteries to power buildings. At the end of their life, they are also recyclable.",
  faq7: "Are EVs actually better for the environment?",
  faq7A:
    "Electric vehicles produce zero exhaust – no nasty chemicals that contribute to smog or other forms of air or water pollution. Switching cars to electric will dramatically improve local air quality, save millions in health costs from respiratory diseases, and reduce the long-term effects of climate change.",
  faq8: "What is an ICE car?",
  faq8A:
    "You may hear or see the term “ICE” when referring to a gas-powered vehicle. “ICE” stands for Internal Combustion Engine. In addition to contributing to harmful greenhouse gases, these cars require more maintenance and have more moving parts than electric vehicles.",
  faqCharging: "Charging",
  faq9: "Where do I charge my EV?",
  "faq9A-1":
    "Do you have a house? If you have a garage or driveway, you can charge at home, so you can leave every morning with a “full tank”. With the range of most EVs, you don't even need to charge every night! You can say goodbye to the gas pump and stop going to the gas station during the week, which saves you time and money. And unlike gasoline that needs a dedicated station, “electric fuel” is available in many more places. Your home, hotels, movie theatres, malls, grocery stores, vacation resorts, and more! You can even plug into a regular outlet.{br} Even when you are not home, you can also charge at hundreds of charging ports available to the public, both in Austin and beyond the city.{br} Finding charging stations is easy. Simply download the {link}, choose your EV and all of the charging stations for your car automatically appear.",
  faq10: "What are the different ways to charge your EV?",
  faq10A:
    "Similar to gas cars that can use “Regular” or “Premium”, EVs can use different plug types. With the Chargeway app your EVs plug(s) are color coded to stations you can use for your selected vehicle. There are three plug colors:",
  "faqContent1-1": "For Chevrolet, BMW, VW and other US / European brands",
  "faqContent1-2": "Both Standard and Fast Charging",
  "faqContent1-3": "Often referred to as J1772 and CCS Combo",
  "faqContent2-1": "For Nissan, Mitsubishi, Kia and some Asia market brands",
  "faqContent2-2": "Fast Charging ONLY",
  "faqContent2-3": "Often referred to 'CHAdeMO'",
  "faqContent3-1": "For Tesla vehicles ONLY",
  "faqContent3-2": "Both Standard and Fast Charging",
  "faqContent3-3":
    "Often referred to as 'Destination Chargers' and 'Superchargers'",
  faq11: "How long does charging take?",
  faq11A:
    "There are seven different power levels for charging including both Standard and Fast Charging. Some EVs can only use standard charging, while others can use standard as well as fast charging.",
  stdCharging: "Standard Charging:",
  "faqContent4-1": "Level 1",
  "faqContent4-2": "This level uses the charging cable provided with every EV",
  "faqContent4-3": "Provides 3-5 miles per hour of charge",
  "faqContent4-4": "Level 2",
  "faqContent4-5":
    "This level offers faster charging at home and is appropriate for workplace charging",
  "faqContent4-6": "Provides 20 to 60 miles per hour of charge",
  faqFastCharge: "Fast Charging:",
  faqContent5AA:
    "EVs capable of fast charging can use levels 3 through 7. Some EVs can faster charge at higher levels than other EVs. Download the {link} to see which stations your vehicle can use and how fast you can charge. Typical fast charging speeds are:{br}Level 3: Provides 80-100 miles per hour of charge{br}Level 4: Provides 101-175 miles per hour of charge{br}Level 5: Provides 200-350 miles per hour of charge{br}Level 6: Provides 400-700 miles per hour of charge{br}Level 7: Provides 1200-1400 miles per hour of charge{br}Fast charging is available for $0.21 per minute, conveniently located near major transit routes for the times you need to charge up and go. If you want to use a public charging port and plan to be away from your vehicle for more than 30 minutes, use a level 2 charger instead of a fast charger.",
  faqContent6: "Do I need to buy a home charging station? If so, which one?",
  faqContent7:
    "New plug-in vehicles come with Green 1 connectors that plug into standard 120-volt household outlets. So, you do not need to buy a home charging station as long as you have access to a standard outlet. However, for faster charging, many drivers buy a Green 2 charging station to plug into a 240-volt outlet. All Green 2 charging stations come with the standard Green connector that allow any plug-in vehicle to connect.{br} Tesla vehicles comes with a Red 1 connector. A Tesla high-powered wall connector (Red 2) can be purchased through Tesla. Tesla owners can also use the standard charging Green adapter to connect to any standard level Green station.",
  faqContent8: "Wouldn't that increase the electric bill?",
  faqContent9C:
    "Yes, but not by much. Electricity costs far less than gasoline for fuel. Compare and calculate your savings {link}.",
  faqHere: "here",
  faqContent10: "Does it take a long time to charge?",
  faqContent11B:
    "Green Level 1 and 2 stations are available at workplaces, retail businesses and other locations. In addition, Fast Charging stations are available for charging on the go within Austin and beyond. To see where you can travel with your EV, download the {link} and use the Trip Planner.",
  faqContent12: "How would I charge my electric car on the go?",
  faqContent13:
    "Level 1 and 2 are available at workplaces, retail businesses and other locations. In addition, a faster option called DC Fast Charge is available for charging on the go.",
  faqContent14: "Austin Energy Plug-In EVerywhere℠ Charging Subscription",
  faqContent15: "What is the Plug-In EVerywhere subscription?",
  faqContent16:
    "The Austin Energy Plug-In EVerywhere network subscription plan offers unlimited charging for $4.17 per month at more than 1000 Plug-In EVerywhere Level 2 charging ports throughout the Austin Energy service area. (Please be advised that multifamily, fleet and workplace charging stations may have restricted access. All of our Plug-In EVerywhere charging ports are powered by 100% Texas wind through our Austin Energy’s GreenChoice program. The subscription is billed on customer’s utility bill $25 every 6 months. We are unable to accept Credit or Debit cards at this time.",
  faqContent17: "Who is eligible to subscribe?",
  faqContent18:
    "Any EV driver who lives, works, or visits Austin is able to subscribe. You do not have to be an Austin Energy electric customer.",
  faqContent19:
    "Can I charge at a Plug-In EVerywhere station if I am not a member?",
  faqContent20:
    "Yes, you may also access the Level 2 stations without a Plug-In EVerywhere membership. Enrollment for the non-member rate is $2/hr.{br} Fast charging is available for $0.21 per minute, conveniently located near major transit routes for the times you need to charge up and go. If you want to use a public charging port and plan to be away from your vehicle for more than 30 minutes, use a level 2 charger instead of a fast charger.",
  faqContent21:
    "A non-electric vehicle is parking at a charging station spot. What can I do?",
  faqContent22:
    "Please call Austin 311 to report a non-electric vehicle parking in a charging station space. (311 connects to parking enforcement)",
  faqContent23: "Vehicles",
  faqContent24: "What is an Electric Vehicle?",
  faqContent25: "Why should I drive an EV?",
  faqContent26: "What should I consider before purchasing an Electric Vehicle?",
  faqContent27: "How fast are EVs?",
  faqContent28: "What maintenance do electric vehicles require?",
  faqContent29: "Are EV batteries better than the use of gasoline in cars?",
  faqContent30: "Are EVs actually better for the environment?",
  faqContent31: "Why should I drive an EV?",
  faqContent32: "What is an ICE car?",
  faqContent33: "Charging",
  faqContent34: "Where do I charge my EV?",
  faqContent35: "What are the different ways to charge your EV?",
  faqContent36: "How long does charging take?",
  faqContent37: "Do I need to buy a home charging station? If so, which one?",
  faqContent38: "Wouldn't that increase the electric bill?",
  faqContent39: "Does it take a long time to charge?",
  faqContent40: "How would I charge my electric car on the go?",
  faqContent41: "What is the Plug-In EVerywhere subscription?",
  faqContent42: "Who is eligible to subscribe?",
  faqContent43:
    "Can I charge at a Plug-In Everywhere station if I am not a member?",
  faqContent44:
    "A non-electric vehicle is parking at a charging station spot. What can I do?",
  faqContent45: "Austin Energy Plug-In EVerywhere&#8480; Charging Subscription",

  "vehicle.miles": "miles",
  "vehicle.trees": "trees",
  "vehicle.view": "View",
  "vehicle.more": "more",
  "vehicle.fewer": "fewer",
  "vehicle.greater": "greater",

  "vehicle.plugInHybrid": "Plug in Hybrid",
  "vehicle.allElectric": "All Electric",
  "vehicle.gas": "Gasoline",
  "vehicle.afterIncentives": "AFTER INCENTIVES",
  "vehicle.msrp": "MSRP",
  "vehicle.estimatedIncentives": "ESTIMATED INCENTIVES",
  "vehicle.type": "TYPE",
  "vehicle.fuelType": "FUEL TYPE",
  "vehicle.batterySize": "BATTERY SIZE",
  "vehicle.electricRange": "ELECTRIC RANGE",
  "vehicle.timeToChargeLvlTwo": "TIME TO CHARGE - LEVEL 2",
  "vehicle.milesPerThirtyFastCharge": "MILES PER 30 MIN OF FAST CHARGING",
  "vehicle.coTwoEmissions": "CO2 EMISSIONS REDUCTION",
  "vehicle.phev": "Electricity and Gasoline",
  "vehicle.bev": "Electricity",
  "vehicle.age.one": "0-1 years old",
  "vehicle.age.two": "2-3 years old",
  "vehicle.age.four": "4+ years old",

  "vehicle.type.sedan": "Sedan",
  "vehicle.type.hatchback": "Hatchback",
  "vehicle.type.coupe": "Coupe",
  "vehicle.type.crossover": "Crossover",
  "vehicle.type.minivan": "Minivan",
  "vehicle.type.suv": "SUV",
  "vehicle.type.wagon": "Wagon",
  "vehicle.type.truck": "Truck",

  "header.electricVehicles": "Electric Vehicles",
  "header.compareVehicles": "Compare Vehicles",
  "header.incentives": "Incentives",
  "header.chargingStations": "Charging Stations",
  "header.homeChargers": "Home Chargers",

  pages: "Pages",

  footerNavigationOtherResources: "Other Austin Energy Resources",

  footerInventory: "Inventory - Austin Energy EV Buyer's Guide",
  findDealers: "Find Dealers",
  evFAQ: "EV FAQ",

  footerNavigationEVPrograms: "Austin Energy EV Programs",
  footerNavigationSolar: "Solar",
  footerNavigationGreenChoice: "GreenChoice®",
  footerNavigationGreenBuilding: "Austin Energy Green Building",
  footerNavigationRebatesIncentives: "Rebates & Incentives",
  footerNavigationContact: "Contact",

  "footer.electricVehicles": "{electricVehicles}",
  "footer.compareVehicles": "{compareVehicles}",
  "footer.incentives": "{incentives}",
  "footer.chargingStations": "{chargingStations}",
  "footer.homeChargers": "{homeChargers}",
  "footer.allRights": "All Rights Reserved.",
  "footer.poweredBy": "Powered by Zappyride",

  "homepage.welcome": "Colton Recharged",
  "homepage.welcomeSub":
    "Your electric car guide, brought to you by Colton Electric Utility. Estimate and compare costs, savings, EV Incentives, and more.",

  "homepage.linkCard.browseElectricVehicles":
    "BROWSE ELECTRIC {lineBreak} VEHICLES",
  "homepage.linkCard.researchElectricVehicles":
    "RESEARCH ELECTRIC {lineBreak} VEHICLES",
  "homepage.linkCard.discoverIncentives": "DISCOVER {lineBreak} INCENTIVES",
  "homepage.linkCard.locateChargingStations":
    "LOCATE CHARGING {lineBreak} STATIONS",
  "homepage.linkCard.homeChargers": "HOME {lineBreak} CHARGERS",
  "homepage.linkCard.findDealers": "FIND {lineBreak} DEALERS",
  "homepage.linkCard.eRides": "Check out {ERides} to access rebates for electric bikes and more",
  "homepage.linkCard.learnMore": "Drive on the F1 COTA track at Electrify Expo this Nov 11-12. It's the biggest expo yet with the world’s best electric cars, trucks, bikes and more. {LearnMore}",

  "homepage.vehicleCarousel.title":
    "There are {vehicleCount} electric vehicles available. Discover yours.",
  "homepage.vehicleCarousel.subTitle":
    "Choose an EV and compare the cost to a similar gas vehicle. EVs can help you save money because {lineBreak} they're often cheaper to own and maintain than a 100% gasoline-powered competitor.",
  "homepage.vehicleCarousel.findEVButton": "See All Vehicles",

  "homepage.fuelRangeChart.title":
    "How far can you drive the {car} on a {budget} fuel budget?",
  "graph.fuelRange.chart.description": "Description",
  "graph.fuelRange.chart.rangeOn": "Range on",
  "graph.fuelRange.chart.fuelBudget": "fuel budget",
  "graph.fuelRange.chart.network": "on Plug-In EVerywhere network",
  "graph.fuelRange.chart.unlimited": "Unlimited",
  "graph.fuelRange.chart.chargedHome": "charged at home",
  "graph.fuelRange.chart.similarGas": "a similar Gas Vehicle",
  "graph.fuelRange.chart.miles": "miles",
  "graph.fuelRange.chart.howFar":
    "How far can you drive the {car} on a {budget} fuel budget?",

  "graph.assumptions": "Assumptions",
  "graph.yourEV": "Your EV Selection",
  "graph.similarGasVehicle": "Similar Gas Vehicle",
  "graph.graphValues": "Graph Values",
  "graph.show": "SHOW ",
  "graph.hide": "HIDE ",
  "graph.moreExpensive": "more expensive",
  "graph.cheaper": "cheaper",
  "graph.toOwnOver": "to own over ",
  "graph.years": " Years",
  "graph.year": " Year",

  "graph.costOfOwnership.title": "Cost of Ownership",
  "graph.costOfOwnership.subTitle":
    "The {carName} is {style} {costDeltaText} {costDeltaTextEnding}",
  "graph.costOfOwnership.subTitleThreeCars":
    "The {carName} is the cheapest to own for {yearsOfOwnership} years",
  "graph.costOfOwnership.descriptionRow.vehicle": "Vehicle",
  "graph.costOfOwnership.descriptionRow.maintenance": "Maintenance",
  "graph.costOfOwnership.descriptionRow.insurance": "Insurance",
  "graph.costOfOwnership.descriptionRow.electricity": "Electricity",
  "graph.costOfOwnership.descriptionRow.gasoline": "Gasoline",
  "graph.costOfOwnership.totalRow": "Total",
  "graph.costOfOwnership.description": "Description",

  "graph.costOfOwnership.chart.vehicle": "Vehicle net Incentives, Resale",
  "graph.costOfOwnership.chart.electricity": "Electricity",
  "graph.costOfOwnership.chart.gasoline": "Gasoline",
  "graph.costOfOwnership.chart.maintenance": "Maintenance",
  "graph.costOfOwnership.chart.insurance": "Insurance",

  "graph.title.costAnalysis": "Cost Analysis",
  "graph.title.speedOfCharge": "Speed of Charge",
  "graph.title.electricRange": "Electric Range",
  "graph.monthlyCostToFill.title": "Cost to Fill Up Monthly",

  "graph.speedOfCharge.levelTwoChargingSpeed": "Level 2 Charger Speed",
  "graph.speedOfCharge.fastChargingSpeed": "Fast Charging Speed",
  "graph.speedOfCharge.mphLevelTwoCharger":
    "miles per hour with a level 2 charger",
  "graph.speedOfCharge.thirtyFastCharge":
    "miles per 30 minutes of fast charging",
  "graph.speedOfCharge.subTitle":
    "The {car} charges {number} {costDeltaText} {subTitleText}",
  "graph.speedOfCharge.mphLevelTwoChargerThreeCars":
    "charges the most miles per hour with a level 2 charger",
  "graph.speedOfCharge.thirtyFastChargeThreeCars":
    "charges the most miles per 30 minutes of fast charging",
  "graph.speedOfCharge.subTitleThreeCars": "The {car} {subTitleText}",
  "graph.speedOfCharge.mphLevelTwoChargerTitle":
    "Amount of Charge Per Hour of Level 2 Charging",
  "graph.speedOfCharge.fastChargerTitle":
    "Amount of Charge Per 30 Minutes of Fast Charging",
  "graph.electricRange.subTitleThreeCars":
    "The {car} has the longest Electric Range",
  "graph.electricRange.subTitle":
    "The {car}'s electric range is {costDeltaText} by {number} miles",
  "graph.electricRange.gasolineRange": "Gasoline Range",
  "graph.electricRange.assumption": "Range based on EPA Estimates",
  "graph.monthlyCostToFill.subTitle":
    "The {carName} is {style} {costDeltaText} to fill up monthly",
  "graph.monthlyCostToFill.subTitleThreeCars":
    "The {carName} is cheapest to fill up monthly",

  "homepage.incentives.title":
    "Explore potential EV incentives and tax credits",
  "homepage.incentives.subTitle":
    "See how much you could save getting behind the wheel of an EV, whether you are buying or leasing. Incentives are personalized for {company} customers.",
  "homepage.incentives.exporeIncentives": "EXPLORE INCENTIVES",

  "eRides.metroBike.title": "MetroBike Access",
  "eRides.metroBike.subtitle":
    "No bike? No problem. Try a {metroBike} and enjoy a smooth ride around town for short trips without buying a bike.",
  "eRides.metroBike": "Metrobike",
  "eRides.metroBike.try": "Try a Metrobike",
  "eRides.metroBike.how": "How to Use",
  "eRides.metroBike.find": "Find Bikes",
  "eRides.metroBike.safety": "Safety Tips",
  "eRides.bikeMap.title": "Austin Bike Map",
  "eRides.bikeMap.subtitle": "Austin’s biking network is growing every day.",
  "eRides.upTo": "Up to",
  "eRides.go": "Go",
  "eRides.rebates.local": "Local",
  "eRides.rebates.oneTitle": "For Individual Customers",
  "eRides.rebates.twoTitle": "For Customer Assistance Program participants",
  "eRides.rebates.threeTitle": "For Fleet customers purchasing 5-25 E-Rides",
  "eRides.rebates.localTitle":
    "Purchases must be made through an eligible dealer to qualify",

  "compareVehicles.subTitle": "See All Vehicles >",
  "compareVehicles.selectFuel": "Select a Fuel Type",
  "compareVehicles.selectMake": "Select a Make",
  "compareVehicles.selectModel": "Select a Model",
  "compareVehicles.pickTwo": "Pick at least 2 vehicles to start the comparison",
  "compareVehicles.disclaimer": "Not all make / models are available.",

  "evs.welcomeSub":
    "Compare electric vehicles by range, price, or your personalized Match Score. Click on the EV for more details, including total cost compared to a similar gas vehicle.",
  "evs.buttonMatchScoreAndFilters": "Match Score and Filters",
  "evs.matchScoreAndFilters": "Refine Match Score and Filters",
  "evs.matchScoreOptions": "Refine Match Score",
  "evs.roundTripCommute": "Roundtrip Commute",
  "evs.budgetAfterIncentives": "Budget after Incentives",
  "evs.minSeats": "Minimum Seats",
  "evs.seats": "seats",
  "evs.homeChargingAvailability": "Home Charging Availability",
  "evs.homeChargingAvailabilityTooltip":
    "More electric vehicles will be convenient for you if you can charge quickly at home.",
  "evs.noCharging": "No Charging",
  "evs.levelOne": "Level 1",
  "evs.levelTwo": "Level 2",
  "evs.helpMeChoose": "Help Me Choose",
  "evs.chargingAvailability": "Charging Availability",
  "evs.disclaimer":
    "Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer.",

  vehiclesAvailabilityButton: "Local Vehicles Only",

  "chargingWizard.carOvernight": "Where will you park your car overnight?",
  "chargingWizard.parkingGarage": "Private garage or parking spot",
  "chargingWizard.onTheStreet": "On the street",
  "chargingWizard.publicParking": "Public parking lot",
  "chargingWizard.electricianInstall":
    "Would an electrician be allowed to install a charging station?",
  "chargingWizard.overnightParking":
    "Could you park the car overnight in a spot equipped with a charging station?",
  "chargingWizard.powerOutlet":
    "Is there a regular power outlet close to where you park your car?",
  "chargingWizard.chargingAvailability": " charging availability",
  "chargingWizard.noChargingAvailability": "no charging availability",
  "chargingWizard.youWouldHave": "you would have {resultText} ",
  "chargingWizard.useThisValue": "Use this value",

  evfilter: "Filter",
  "evfilter.fuel": "Fuel",
  "evfilter.fuelTooltip":
    "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",
  "evfilter.type": "Type",

  yes: "Yes",
  no: "No",
  availability: "Availability",
  "availability-Tooltip":
    "These vehicles have been seen in local Austin area dealer inventory in the recent past.",

  vehiclesFuelTip:
    "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",

  "evCard.electricRange": "Electric Range",
  "evCard.totalRange": "Total Range",
  "evCard.matchScore": "MATCH SCORE",
  "evCard.seeDetails": "See Details",
  "evCard.seeElectricVehicle": "See Electric Vehicle",

  "evSort.title": "Sort By",
  "evSort.matchScore": "Match Score",
  "evSort.electricRange": "Electric Range",
  "evSort.priceLtH": "Price: Low to High",
  "evSort.priceHtL": "Price: High to Low",
  "evSort.alphabetical": "Alphabetical",

  inventoryFilter: "Filter Vehicles",
  inventoryAll: "All",
  inventoryNew: "New",
  inventoryUsed: "Used",

  "pricePanels.cash": "Cash",
  "pricePanels.loan": "Loan",
  "pricePanels.lease": "Lease",
  "pricePanels.loanPayment": "Loan Payment",
  "pricePanels.downPayment": "Down Payment",
  "pricePanels.leasePayment": "Lease Payment",
  "pricePanels.perMonth": " / month",
  "pricePanels.downPaymentDescription": "10% of MSRP plus tax",
  "pricePanels.firstLeasePayment": "FIRST LEASE PAYMENT",
  "pricePanels.incentivesForLease": "Incentives For Lease",
  "pricePanels.seeAllIncentives": "See All Incentives",
  "pricePanels.seeAllCosts": "See All Costs",

  "ev.jumbotron.viewAllCars": "View All Cars",
  "ev.jumbotron.disclaimer":
    "See the {evFacts} and the {faq} pages for detailed explanations about your EV.",
  "ev.carDetails.fastChargingTooltip": "DC Fast Charger Speed:",
  "ev.carDetails.levelTwoChargerSpeedTooltip": "Level 2 Charger Speed:",
  "ev.carDetails.batterySizeTooltip":
    "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh.",
  "ev.carDetails.treesPlanted": "Trees Planted",
  "ev.carDetails.gasolineSaved": "Gasoline Saved",
  "ev.carDetails.compareText":
    "We selected the {gasVehicle} based on its proximity to the {vehicle} based on brand, size, and price.",
  "ev.cardetails.calc.dcRate": "DC Fast Charging (DCFC) Rate",
  "ev.cardetails.calc.EPA": "EPA Efficiency Rating",
  "ev.cardetails.calc.chargingRate": "Charging Rate",
  "ev.cardetails.calc.vehicleBatteryCapacity": "Vehicle Battery Capacity",
  "ev.cardetails.calc.maxACIntake": "Vehicle Max AC Intake",
  "ev.cardetails.calc.lvlTwoChargingRate": "Level 2 Charging Rate",
  "ev.cardetails.calc.minOflvl2AC":
    "Minimum of Level 2 Charging Rate and Vehicle Max AC Intake",
  "ev.cardetails.calc.lvl2FullCharge": "Time to Full Charge for Level 2",
  "ev.cardetails.calc.milesDrivenPerYr": "Miles driven per year",
  "ev.cardetails.calc.userInput": "User Input",
  "ev.cardetails.calc.milesPerGallon": "Miles Per Gallon (MPG)",
  "ev.cardetails.calc.mpg": " miles/gal",
  "ev.cardetails.calc.portionElectric": "Portion of Electric Driving",
  "ev.cardetails.calc.portionElectricSource":
    "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.",
  "ev.cardetails.calc.gallonsUsed": "Gallons Used",
  "ev.cardetails.calc.gallonsUsedFormula":
    "Miles driven * (1 - Portion of Electric Driving) / MPG",
  "ev.cardetails.calc.gallonsSaved": "Gallons Saved",
  "ev.cardetails.calc.gallonsSavedFormula":
    "Gallons used by Equivalent Gas Vehicle - Gallons used by EV",
  "ev.cardetails.calc.electricEmissions": "Electric Emissions in Lbs",
  "ev.cardetails.calc.electricEmissionsLbsMwh": "Electric Emissions in Lbs/MWh",
  "ev.cardetails.calc.EIA": "U.S. Energy Information Administration",
  "ev.cardetails.calc.gasolineEmissionsYr":
    "Gasoline Emissions in lbs of CO2/yr",
  "ev.cardetails.calc.gasolineEmissions":
    "Gasoline Emissions in Lbs per Gallon",
  "ev.cardetails.calc.emissionsReudctions": "Emissions Reductions",
  "ev.cardetails.calc.lbsCo2PerYr": " lbs of CO2 per year",
  "ev.cardetails.calc.gasolineEmissionsLbs": "Gasoline Emissions in Lbs",
  "ev.cardetails.calc.arborDayFoundation": "Arbor Day Founadtion",
  "ev.cardetails.calc.emissionsPerTree": "Emissions Saved per Tree",
  "ev.cardetails.calc.electricityEmissions":
    "Electricity Emissions in CO2 lbs/MWh",
  "ev.usedCarTitle": "Used {car}",
  "ev.cardetails.electricRange":
    "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
  "ev.carDetails.usedBatterySizeTooltip":
    "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",

  "ev.purchaseMethod": "Purchase Method",
  "ev.purchaseMethodToolTip":
    "In a lease, you have to return the car after the lease period. In the case of loan financing, you keep the car after the loan is paid off.",
  "ev.cash": "Cash",
  "ev.loan": "Loan",
  "ev.lease": "Lease",
  "ev.milesDrivenAnnually": "Miles Driven Per Year",
  "ev.electricPortion": "Portion Electric for PHEV",
  "ev.electricPortionTooltip":
    "A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.",
  "ev.yearsOwnership": "Years of Ownership/Lease",
  "ev.interestRate": "Interest Rate",
  "ev.electricityRateCalc":
    "Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}",

  "assumption.salesTax": "Sales Tax",
  "assumption.gasolinePrice": "Price of Gasoline",
  "assumption.downPayment": "Down Payment",
  "assumption.electricityRate": "Electricity Rate",
  "assumption.downPaymentValue": "10% of MSRP + Tax",

  "ev.compareGasVehicle":
    "Compare the {car} to a similar gas vehicle, the {gasCar}",
  "ev.compareOtherVehicles": "COMPARE OTHER VEHICLES",
  "ev.incentives.title":
    "Up to {savings} in tax credits and rebates are potentially available for the {car}",
  "ev.reviews": "Reviews from around the web",

  "chargingMap.updateZipcode": "UPDATE ZIP CODE",
  "chargingMap.errorZipcode": "Error: {workingZipcode} is not a valid zipcode",
  "chargingMap.showFullListOfStations": "FULL LIST OF STATIONS",
  "chargingMap.public": "Public stations",
  "chargingMap.businessGovernment": "Installed by business or government",
  "chargingMap.highPowerStations": "High Power Stations",
  "chargingMap.DCChargers": "DC fast charge or superchargers",
  "chargingMap.otherSttations": "Other Types of Stations",
  "chargingMap.privateStations": "Private stations",
  "chargingMap.searchDealers": "Search Qualified Dealers",

  "dealers.brands": "Brands",
  "dealers.allBrands": "All Brands",
  "dealers.allDealers": "All Dealers",
  "dealers.contact": "CONTACT",
  "dealers.website": "WEBSITE",
  "dealers.findDealers": "Find Dealers",

  "incentives.title": "Electric Vehicle Incentives",
  "incentives.subTitle":
    "You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Incentives are personalized for where you live.",
  "incentives.single": "Single",
  "incentives.married": "Married",
  "incentives.headOfHousehold": "Head of Household",
  "incentives.vehicleType": "Vehicle Type",
  "incentives.vehicleTypeTooltip":
    "Certain incentives depend on which car you purchase.",
  "incentives.tradeIn": "Trade-In",
  "incentives.income": "Income and tax status",
  "incentives.incomeTooltip":
    "Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates.",
  "incentives.clunkerNone":
    "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker.",
  "incentives.clunkertoolTip":
    "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker. Specifically, the ",
  "incentives.clunkertoolTipContd":
    " are available in your region. Eligibility requirements vary by incentive; please check program details.",
  "incentives.householdSize": "Household Size",
  "incentives.householdIncome": "Household Income",
  "incentives.planClunker": "Are you planning to turn in a clunker?",
  "incentives.location": "Location",
  "incentives.locationTooltip":
    "Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live.",
  "incentives.taxFilingStatus": "Tax Filing Status",
  "incentives.whichVehicle": "Which vehicle do you plan to purchase?",
  "incentives.incentiveEligibility": "Update Incentive Eligibility",

  "homeChargers.subText":
    "Select the vehicle you are interested in to see the specific charging time to a full battery.",
  "homeChargers.wallMounted": "Wall Mounted",
  "homeChargers.portable": "Portable",
  "homeChargers.price": "Price",
  "homeChargers.cordLength": "Cord Length",
  "homeChargers.wifi": "WiFi Connectivity",
  "homeChargers.socket": "Socket",
  "homeChargers.socketTooltip":
    "Different model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're note sure which is best for your home/garage, check with a qualified electrician.",
  "homeChargers.cordLengthLtH": "Cord Length: Low to High",
  "homeChargers.cordLengthHtL": "Cord Length: High to Low",
  "homeChargersCard.buy": "Details & Buy",
  "homeChargersCard.beforeIncentives": "Before Incentives",
  "homeChargersCard.socket": "{socket} Socket",
  "homeChargers.cable": "{length} ft. Cable",
  "homeChargers.hardwired": "Hardwired",
  "homeChargers.toFullCharge": "to full charge",

  "electricians.residential": "Residential",
  "electricians.commercial": "Commercial",
  "electricians.industrial": "Industrial",
  "electricians.type": "Electrician Type",
  "electricians.searchElectricians": "Search Qualified Electricians",

  "activate.membership": "Activate your",
};

