import React from "react";
import PropTypes from "prop-types";
import "./ERidesCard.scss";
import { FormattedMessage } from 'react-intl';


const ERidesCard = ({
   id,
  price,
  title,
  image,
  link
}) => {

  return (
    <a
       id={id}
      className="ERidesCard"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className="ERidesCardTop"
        style={
          price === "Local"
            ? { borderTop: "8px solid #B9BCC0" }
            : { borderTop: "8px solid #F25C2a" }
        }
      >
        <p className="up-to">
          {price === "Local" ? (
            <FormattedMessage
              id="eRides.go"
              defaultMessage="Go"
              description="go price"
            />
          ) : (
            <FormattedMessage
              id="eRides.upTo"
              defaultMessage="Up to"
              description="up to pricee"
            />
          )}
        </p>
        <p className="price">{price}</p>
      </div>
      <div className="text-center">
        <img src={image} alt={"ERides"} className="img-fluid" />;
      </div>
      <div className="ERidesCardBottom">{title}</div>
    </a>
  );
};

export default ERidesCard;

ERidesCard.propTypes = {
  price: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.shape({
    type: PropTypes.oneOf(["jpg", "svg"]),
  }),
};
