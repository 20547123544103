import React from "react";
import PropTypes from "prop-types";

import ChargingPinPublic from "../../../client_customizations/assets/images/icons/charger-pin-public.png";
import ChargingPinHighPower from "../../../client_customizations/assets/images/icons/charger-pin-high-power.png";
import ChargingPinDefault from "../../../client_customizations/assets/images/icons/charger-pin-private.png";

const MapLegend = ({ ignoreSuperchargerStations }) => {
  return (
    <div className="legend">
      <div className="charger-type">
        <b>Public stations</b>
        <br />
        <span>Installed by business or government</span>
        <img src={ChargingPinPublic} alt="" />
      </div>
      {!ignoreSuperchargerStations && (
        <div className="charger-type">
          <b>High Power Stations</b>
          <br />
          <span>DC fast charge or superchargers</span>
          <img src={ChargingPinHighPower} alt="" />
        </div>
      )}
      <div className="charger-type">
        <b>Other Types of Stations</b>
        <br />
        <span>Private stations</span>
        <img src={ChargingPinDefault} alt="" />
      </div>
    </div>
  );
};

export default MapLegend;

MapLegend.propTypes = {
  ignoreSuperchargerStations: PropTypes.bool
};
