import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Range from "../../shared/InputElements/Range";

const descriptionFn = val => {
    return `< ${val} ft`;
};

const SlideHomeChargerCordLength = ({
  id = "charger-cord-range",
  label = "Cord Length",
  description = descriptionFn,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("chargerCordLength")}
      label={label}
      ariaLabel={label}
      rangeMin={15}
      rangeMax={30}
      rangeStep={5}
      description={description}
      ariaControls="home-chargers-catalog"
      handler={e => userPrefs.set({ chargerCordLength: e.target.value })}
      {...rest}
    />
  );
};

export default SlideHomeChargerCordLength;

SlideHomeChargerCordLength.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
