import React from "react";
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import getFilteredData from "../../../../utils/Helpers/getFilteredData"
import IncentiveCatalog from "../../../../components/IncentiveCatalog/IncentiveCatalog";

const HomepageIncentives = ({ incentives }) => {

  incentives = getFilteredData([
    { field: "grantor", value: "Federal", count: 1 },
    { field: "grantor_type", value: "State", count: 2 },
    { field: "grantor_type", value: "Power Supplier", count: 3 }
  ], incentives)

  return (
    <section className="container pb-0" id="HomepageIncentives" aria-label="Homepage Incentives">
      <div className="row">
        <div className="col-sm-12">
          <h2>
          <FormattedMessage 
            id="homepage.incentives.title"
            defaultMessage="Explore potential EV incentives and tax credits"
            description="Homepage Incentives Title"
          />
          </h2>
          <p className="lead">
            <FormattedMessage 
              id="homepage.incentives.subTitle"
              defaultMessage="See how much you could save getting behind the wheel of an EV, whether you are buying or leasing. Incentives are personalized for {COMPANY_NAME} customers."
              description="Homepage Incentives Sub Title"
              values= {{
                company: process.env.REACT_APP_FULL_COMPANY_NAME,
              }}
            />
          </p>
        </div>
      </div>
        <IncentiveCatalog incentives={incentives} category="all" />
      <p className="incentives-disclaimer">Live in an apartment building? Landlords and tenants, <strong><a href="https://austinenergy.com/green-power/plug-in-austin/workplace-charging" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>click here</a></strong> to learn about our Multi-Family Charging programs.</p>
      <hr className="mt-5" />
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
