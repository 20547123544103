const capitalizedInPurchase = incentives => {
  let incentivesArray = incentives || [];

  return incentivesArray.reduce((acc, incentive) => {
    let incentiveAmount =
      incentive &&
      incentive.evaluation &&
      incentive.evaluation.applicable_to_ownership &&
      !isNaN(
        parseInt(incentive.evaluation.amount_in_purchase, 10)
      )
        ? parseInt(incentive.evaluation.amount_in_purchase, 10)
        : 0;

    return acc + incentiveAmount;
  }, 0);
};

export default capitalizedInPurchase;
