import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import HomepageIncentives from "./components/HomepageIncentives";
import getLocalVehicles from "../../../functions/vehicle/getLocalVehicles";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehiclesCarousel from "../../../components/VehicleCarousel/VehicleCarousel"
import ChargingMap from "../LocationMap/ChargingMap"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";


const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  chargingStations,
  userLocation,
  ip,
  uuid
}) => {

  const [vehicles, setVehicles] = useState([]);
  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  }

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_TITLE;
    setElectricVehicles();
  });

  const renderDetails = electricVehicles && incentives ?
    (
      <>
        <VehiclesCarousel
          electricVehicles={getLocalVehicles(vehicles)}
        />
        <HomepageIncentives incentives={incentives} />
      </>
    ) : (
      <section className="container">
        <LoadingSpinner />
      </section>
    );

  return (
    <>
    <div className="homepage-banner">
     <p>Dreams can come true! Get on the F1 COTA track at the Electrify Expo Nov 9-10. It's the biggest ever with the best electric cars, trucks, bikes and more. <a href="https://www.electrifyexpo.com/austin">Learn More</a>.</p>
     <p>Get your Austin Energy Customer discounted 25% off tickets here: <a href="https://electrifyexpo.ticketbud.com/electrify-expo-2024-austin-november-9-10-03f60058-2454837affd9?pc=austinenergyEV25">Electrify Expo 2024: Austin ~ November 9-10 | Buy Tickets in Del Valle | Ticketbud</a></p>
     </div>
      <HomepageBigPromise
        homePageBannerImage={homePageBannerImage}
      />
      {renderDetails}
      <div id="HomepageChargingStations">
        <ChargingMap
          chargingStations={chargingStations}
          userLocation={userLocation}
        />
      </div>
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
