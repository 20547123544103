import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import "./InventoryEVCard.scss";

import { FormatAsDollars } from "../../utils/Helpers/Format";

import IconBEV from "../../client_customizations/assets/images/icons/icon-electric.png";
import IconPHEV from "../../client_customizations/assets/images/icons/icon-hybrid.png";
import mapIcon from "../../client_customizations/assets/images/icons/orangeMapIcon.svg";
import isBEV from "../../functions/vehicle/isBEV";
import isPHEV from "../../functions/vehicle/isPHEV";
import GaTracker from "../../utils/GaTracker/GaTracker";

const InventoryEVCard = ({ ev }) => {
  const [renderNoImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    async function findValidImage() {
      const checkImage = (imgUrl) => {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.src = imgUrl;
          img.alt = "preview of vehicle";
          img.addEventListener("load", () => resolve(img));
        });
      };

      var imgData = await checkImage(ev.vehicle_dealership_images[0]);
      if (imgData.width > 0) {
        setImageUrl(imgData.src);
        return;
      }
    }
    findValidImage();

    return () => {
      setImageUrl("");
    };
  }, [ev, setImageUrl]);

  if (!ev || imageUrl === "") return null;

  const handleClickWebsite = () => {
    GaTracker.trackEvent({
      category: "Inventory",
      action: "Clicked on Inventory",
      label: `${ev.make} ${ev.model}`,
    });
  };

  const renderFuelTypeBadge = isBEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="All-Electric" src={IconBEV} height="25" />
    </span>
  ) : isPHEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="Hybrid" src={IconPHEV} height="25" />
    </span>
  ) : null;

  const vehicleImage = <img src={imageUrl} alt="preview of vehicle" />;

  const disclaimer = (
    <p>
      Disclaimer: This image is a stock photo. Please View Vehicle Details for
      accurate images.
    </p>
  );

  return (
    <>
      {/* Desktop View */}
      <div className="d-none d-lg-flex inventory-card flex-column">
        <div className="row">
          <div className="col-4 p-0">{vehicleImage}</div>
          <div className="col-8 d-flex flex-column justify-content-around inventory-card-details">
            <div className="row">
              <div className="col-10">
                <span className="model">{`${ev.make}`}</span>{" "}
                <span className="make">{ev.model}</span>
              </div>
              <div className="col-2">{renderFuelTypeBadge}</div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="row detail-title">Year</div>
                <div className="row detail">{ev.model_year}</div>
              </div>
              <div className="col-3">
                <div className="row detail-title">Electric Range</div>
                <div className="row detail">{`${ev.electric_range} miles`}</div>
              </div>
              <div className="col-3">
                <div className="row detail-title">Total Range</div>
                <div className="row detail">{`${ev.total_range} miles`}</div>
              </div>
              <div className="col-3">
                <div className="row detail-title">Price</div>
                <div className="row detail">
                  {!ev.vehicle_dealership_price ||
                  Number(ev.vehicle_dealership_price) === 0 ? (
                    <FormattedMessage
                      id="inventory-card.not-available"
                      defaultMessage="Not Available"
                      description="Not Available"
                    />
                  ) : (
                    FormatAsDollars(ev.vehicle_dealership_price)
                  )}
                </div>
              </div>
            </div>
            <div className="row inventory-card-bottom">
              <div className="col-7 my-auto">
                <img src={mapIcon} alt="pin" />
                <a
                  href={ev.dealer.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="dealer-name">{ev.dealer.name}</span>
                </a>
              </div>
              <div className="col-5">
                <div className="vehicle-details">
                  <a
                    href={ev.vehicle_dealership_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClickWebsite}
                  >
                    <span>
                      <FormattedMessage
                        id="viewVehicleDetails"
                        defaultMessage="View Vehicle Details"
                        description="View Vehicle Details"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderNoImage ? (
          <div className="row stock-image-disclaimer">{disclaimer}</div>
        ) : null}
      </div>

      {/* Mobile View */}
      <div className="d-sm-flex d-md-none d-lg-none inventory-card">
        <div className="row p-0">
          <div className="col-12 text-center">{vehicleImage}</div>
        </div>
        <div className="col-12 d-flex flex-column justify-content-around inventory-card-details">
          <div className="row pt-2">
            <div className="col-10">
              <span className="model">{`${ev.make}`}</span>{" "}
              <span className="make">{ev.model}</span>
            </div>
            <div className="col-2">{renderFuelTypeBadge}</div>
          </div>
          <div className="row justify-content-around">
            <div className="">
              <div className="row detail-title">Year</div>
              <div className="row detail">{ev.model_year}</div>
            </div>
            <div className="">
              <div className="row detail-title">Electric Range</div>
              <div className="row detail">{`${ev.electric_range} miles`}</div>
            </div>
            <div className="">
              <div className="row detail-title">Total Range</div>
              <div className="row detail">{`${ev.total_range} miles`}</div>
            </div>
            <div className="">
              <div className="row detail-title">Price</div>
              <div className="row detail">
                {FormatAsDollars(ev.vehicle_dealership_price)}
              </div>
            </div>
          </div>
          <div className="row inventory-card-bottom pt-2 pb-2">
            <div className="col-6 my-auto pr-0">
              <img src={mapIcon} alt="pin" />
              <a
                href={ev.dealer.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="dealer-name">{ev.dealer.name}</span>
              </a>
            </div>
            <div className="col-6">
              <div className="vehicle-details">
                <a
                  href={ev.vehicle_dealership_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <FormattedMessage
                      id="shopDealers"
                      defaultMessage="Shop Dealers"
                      description="Shop Dealers"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {renderNoImage ? (
          <div className="row stock-image-disclaimer m-0">{disclaimer}</div>
        ) : null}
      </div>
    </>
  );
};

export default InventoryEVCard;

InventoryEVCard.propTypes = {
  ev: PropTypes.object,
};
