import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import unique from "lodash/uniq"

import Select from "../shared/InputElements/Select"

const SelectVehicleModelFilter = ({
  id = "select-vehicle-make-filter",
  vehicles,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const make = userPrefs.get("vehicleMakeFilter")
  let vehicleModelOptions = make !== "All" ? vehicles.filter(ev => ev.make === make) : [];
  vehicleModelOptions = unique((vehicleModelOptions || []).map((ev) => ev.model))
  const label = "Model"

  return (
      <Select
        disabled={make === "All"}
        id={id}
        value={userPrefs.get("vehicleModelFilter")}
        label={label}
        optionNames={["All", ...vehicleModelOptions]}
        optionValues={["All", ...vehicleModelOptions]}
        handler={(e) => userPrefs.set({ vehicleModelFilter: e.target.value })}
      />
  );
};

export default SelectVehicleModelFilter;

SelectVehicleModelFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
}; 
