import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverBody } from "reactstrap";
import "./MatchScorePopover.scss"

const MatchScorePopover = ({ score, large }) => {
  const [showPopover, setPopover] = useState(false);
  const [id] = useState(
    "key" +
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
  );
  if (!score) return null;
  const value = score.total ? score.total : 0;
  const range = score.range ? score.range : 0;
  const charging = score.charging ? score.charging : 0;
  const seats = score.seats ? score.seats : 0;
  const budgetAdjustment = score.budgetAdjustment ? score.budgetAdjustment : 0;

  let MatchScorePopoverValueClass = value > 85 ? "ScoreValueGreaterThanEightyFive" : value > 70 ? "ScoreValueGreaterThanSeventy" : "ScoreValueLessThanSeventy";

  let renderTable = (
    <table className="table table-borderless">
      <thead>
        <tr>
          <th scope="col" />
          <th scope="col">Max Score</th>
          <th scope="col">Your Score</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Range</th>
          <td>45</td>
          <td>{range}</td>
        </tr>
        <tr>
          <th scope="row">Charging</th>
          <td>30</td>
          <td>{charging}</td>
        </tr>
        <tr>
          <th scope="row">Seats</th>
          <td>25</td>
          <td>{seats}</td>
        </tr>
        <tr>
          <th scope="row">Budget&nbsp;Adjustment</th>
          <td>-</td>
          <td>- {budgetAdjustment === 0 ? "" : budgetAdjustment}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="row">Total Score</th>
          <td>100</td>
          <td className="MatchScore">
            <strong className={MatchScorePopoverValueClass}>{value}</strong>
          </td>
        </tr>
      </tfoot>
    </table>
  );

  let renderPopover = showPopover ? (
    <Popover
      placement="bottom"
      isOpen={showPopover}
      target={id}
      className="MatchScorePopover"
    >
      <PopoverBody className="renderRowOfData">
        <p className="h4">Match Score</p>
        <p>Your Match Score is based on your selected preferences.</p>
        {renderTable}
      </PopoverBody>
    </Popover>
  ) : (
    ""
  );

  let renderOffscreenText = showPopover ? (
    <div style={{ position: "absolute", left: "-9999px" }}>{renderTable}</div>
  ) : null;

  let classes = (large ? "h2 RenderItemValue " : "RenderItemValue ") + MatchScorePopoverValueClass;
  
  return (
    <div className="MatchScore" >
      <p
        id={id}
        className={classes} 
        onMouseOver={e => setPopover(true)}
        onMouseLeave={e => setPopover(false)}
        onFocus={e => setPopover(true)}
        onBlur={e => setPopover(false)}
        tabIndex="0"
      >
        {value}
        <span className="denominator">&nbsp;/100</span>
      </p>
      {renderPopover}
      {renderOffscreenText}
    </div>
  );
};

export default MatchScorePopover;

MatchScorePopover.propTypes = {
  score: PropTypes.object,
  large: PropTypes.bool
};
