import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from 'react-intl';
import fetchElectricians from "../../services/fetchElectricians";
import Select from "../../components/shared/InputElements/Select"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import GenericInputZipcode from "../../components/InputComponents/InputZipcode/GenericInputZipcode"
import Map from "../../components/LocationMap/Map/Map";
import SelectedCard from "../../components/SelectedCard/SelectedCard"

const Electricians = ({ userLocation, zipcode, ip, uuid }) => {
  const [selectedElectrician, setSelectedElectrician] = useState('')
  const [electricianType, setElectricianType] = useState('All')
  const [electricians, setElectricians] = useState([])
  const intl = useIntl()

  useEffect ( () => {
    let ignore = false;

    async function fetchData() {
      const params = {
        postcode: zipcode,
        distance: 40
      }
      try {
        const electricians = await fetchElectricians(params)
        if (!ignore) setElectricians(electricians)
      } catch (e) {
        console.log("Failed to load electricians")
      }
    }

    fetchData()
    document.title = process.env.REACT_APP_PAGES_ELECTRICIANS_TITLE;

    return () => { ignore = true; }

  }, [zipcode])

  const findElectrician = () => electricians.find(electrician => electrician.name === selectedElectrician)

  const renderElectricians = electricians.filter(electrician => {
    if (electricianType === "All") {
      return true
    }
    return electrician[electricianType.toLowerCase()] === 1
  })

  const SelectElectricianType = () => {

    return (
      <div className="form-group">
        <Select 
          value={electricianType} 
          handler={(e) => setElectricianType(e.target.value)} 
          label={intl.formatMessage({ id: "electricians.type", defaultMessage: "Electrician Type"})}
          ariaLabel={intl.formatMessage({ id: "electricians.type", defaultMessage: "Electrician Type"})}
          optionNames={[intl.formatMessage({ id: "all", defaultMessage: "All"}), intl.formatMessage({ id: "electricians.residential", defaultMessage: "Residential"}), intl.formatMessage({ id: "electricians.commercial", defaultMessage: "Commercial"}), intl.formatMessage({ id: "electricians.industrial", defaultMessage: "Industrial"})]}
          optionValues={["All", "Residential", "Commercial", "Industrial"]}
        />
      </div>
    );
  };
  
  const body = !userLocation ? (
    <div className="spinner-container">
      <LoadingSpinner />
    </div>
  ) : (
    <>
        <div className="col-sm-3 left-column">
            <div className="controls">              
              <SelectElectricianType />

              <GenericInputZipcode buttonText={intl.formatMessage({ id: "electricians.searchElectricians", defaultMessage: "Search Qualified Electricians"})}/>
            </div>
            {findElectrician() && (
              <div className="selected-dealer-container">
                <SelectedCard
                  {...findElectrician()}
                />
              </div>
            )}
        </div>
        <div className="col-sm-9">
          <div className="map-container">
            <Map
              chargingStations={renderElectricians}
              userLocation={userLocation}
              selectedStationId={selectedElectrician}
              onHoverOnStation={setSelectedElectrician}
              zoom={10}
            />
          </div>
        </div>

      <span style={{ height: "70px", width: "100%" }}>&nbsp;</span>
  
    </>
  );

  return (
    <>
      <h1 className="hide-offscreen">Electricians</h1>
      <section className="container dealer-container">
      <div className="DealerMap">
        <div className="row">
          <div className="col-sm-12">
            <h2>
              <FormattedMessage 
                id="electricians"
                defaultMessage="Electricians"
                description="Electricians"
              />   
            </h2>
          </div>
        </div>
          <div className="row dealerMap-content">
            {body}
          </div>
        </div>
    </section>
    </>
  );
};

export default Electricians;

Electricians.propTypes = {
  chargingStations: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
